li{list-style: none;}
.focus{outline:1px dotted rgba(0,0,0,0.5) !important}
.colors{clear:both;padding:24px 0 9px;}
.skin-line .colors{padding-top:28px}
.colors strong{float:left;line-height:20px;margin-right:20px}
.colors li{position:relative;float:left;width:16px;height:16px;margin:2px 1px 0 0;background:#000;cursor:pointer;filter:alpha(opacity=50);opacity:.5;-webkit-transition:opacity .2s;-moz-transition:opacity .2s;-ms-transition:opacity .2s;-o-transition:opacity .2s;transition:opacity .2s;}
.colors li:hover{filter:alpha(opacity=100);opacity:1}
.colors li.active{height:20px;margin-top:0;filter:alpha(opacity=75);opacity:.75}
.colors li.red{background:#d54e21}
.colors li.green{background:#78a300}
.colors li.blue{background:#0e76a8}
.colors li.aero{background:#9cc2cb}
.colors li.grey{background:#73716e}
.colors li.orange{background:#f70}
.colors li.yellow{background:#fc0}
.colors li.pink{background:#ff66b5}
.colors li.purple{background:#6a5a8c}
.skin-square .colors li.red{background:#e56c69}
.skin-square .colors li.green{background:#1b7e5a}
.skin-square .colors li.blue{background:#2489c5}
.skin-square .colors li.aero{background:#9cc2cb}
.skin-square .colors li.grey{background:#73716e}
.skin-square .colors li.yellow{background:#fc3}
.skin-square .colors li.pink{background:#a77a94}
.skin-square .colors li.purple{background:#6a5a8c}
.skin-square .colors li.orange{background:#f70}
.skin-flat .colors li.red{background:#ec7063}
.skin-flat .colors li.green{background:#1abc9c}
.skin-flat .colors li.blue{background:#3498db}
.skin-flat .colors li.grey{background:#95a5a6}
.skin-flat .colors li.orange{background:#f39c12}
.skin-flat .colors li.yellow{background:#f1c40f}
.skin-flat .colors li.pink{background:#af7ac5}
.skin-flat .colors li.purple{background:#8677a7}
.skin-line .colors li.yellow{background:#ffc414}