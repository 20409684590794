a:focus
{
    outline: none;
}

.font-small-1
{
    font-size: .7rem !important;
}

.font-small-2
{
    font-size: .8rem !important;
}

.font-small-3
{
    font-size: .9rem !important;
}

.font-medium-1
{
    font-size: 1.1rem !important;
}

.font-medium-2
{
    font-size: 1.2rem !important;
}

.font-medium-3
{
    font-size: 1.3rem !important;
}

.font-medium-4
{
    font-size: 1.4rem !important;
}

.font-medium-5
{
    font-size: 1.5rem !important;
}

.font-large-1
{
    font-size: 2rem !important;
}

.font-large-2
{
    font-size: 3rem !important;
}

.font-large-3
{
    font-size: 4rem !important;
}

.font-large-4
{
    font-size: 5rem !important;
}

.font-large-5
{
    font-size: 6rem !important;
}

.text-bold-300
{
    font-weight: 300;
}

.text-bold-400
{
    font-weight: 400;
}

.text-bold-500
{
    font-weight: 500;
}

.text-bold-600
{
    font-weight: 600;
}

.text-bold-700
{
    font-weight: 700;
}

.text-italic
{
    font-style: italic;
}

.text-highlight
{
    padding: 4px 6px;
}

ol li,
ul li,
dl li
{
    line-height: 1.8;
}

code
{
    font-size: 90%;

    padding: .2rem .4rem;

    color: #f74b5c;
    border-radius: .25rem; 
    background-color: #f2f3f5;
}

pre
{
    background-color: #f7f7f9;
}
pre code
{
    background-color: transparent !important;
}

.bd-example-row .row + .row
{
    margin-top: 1rem;
}

.bd-example-row .row > .col,
.bd-example-row .row > [class^='col-']
{
    padding-top: .75rem;
    padding-bottom: .75rem;

    border: 1px solid rgba(86, 61, 124, .2); 
    background-color: rgba(86, 61, 124, .15);
}

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom
{
    min-height: 6rem;

    background-color: rgba(255, 0, 0, .1);
}

.bd-example-row-flex-cols .row
{
    min-height: 10rem;

    background-color: rgba(255, 0, 0, .1);
}

.bd-highlight
{
    border: 1px solid rgba(86, 61, 124, .15); 
    background-color: rgba(86, 61, 124, .15);
}

.example-container
{
    width: 800px;
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}

.example-row
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-right: -15px;
    margin-left: -15px; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.example-content-main
{
    position: relative;

    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .example-content-main
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
}
@media (min-width: 992px)
{
    .example-content-main
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
}

.example-content-secondary
{
    position: relative;

    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .example-content-secondary
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
}
@media (min-width: 992px)
{
    .example-content-secondary
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
}

.table-white-space th,
.table-white-space td
{
    white-space: nowrap;
}

.table-borderless td,
.table-borderless th
{
    border: none;
}

.table thead th
{
    vertical-align: bottom;

    border-top: 1px solid #e3ebf3; 
    border-bottom: 2px solid #e3ebf3;
}

.table-bordered th,
.table-bordered td
{
    border: 1px solid #e3ebf3;
}

.table th,
.table td
{
    border-bottom: 1px solid #e3ebf3;
}

.table.table-padded
{
    overflow: visible; 

    border-spacing: 0 7px;
    border-collapse: separate;
}
.table.table-padded tbody > tr
{
    background-color: #fff;
}
.table.table-padded td
{
    border-top: 0;
}
.table.table-padded thead th
{
    font-size: .85rem; 

    text-transform: uppercase;
}

.table-bordered
{
    border: 1px solid #e3ebf3;
}

.table-inverse
{
    color: #fff;
    background-color: #464855;
}

.table.table-column th,
.table.table-column td
{
    border: none;
    border-left: 1px solid #e3ebf3;
}

.table.table-column th:first-child,
.table.table-column td:first-child
{
    border-left: none;
}

.table th,
.table td
{
    padding: .75rem 2rem;
}

.table.table-xl th,
.table.table-xl td
{
    padding: 1.25rem 2rem;
}

.table.table-lg th,
.table.table-lg td
{
    padding: .9rem 2rem;
}

.table.table-de th,
.table.table-de td
{
    padding: .75rem 2rem;
}

.table.table-sm th,
.table.table-sm td
{
    padding: .6rem 2rem;
}

.table.table-xs th,
.table.table-xs td
{
    padding: .4rem 2rem;
}

/*
* Table sizing
*/
.table-borderless thead th
{
    border-top: 1px solid #e3ebf3;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th
{
    border: 0;
}

.table > thead > tr.border-solid > th
{
    border-bottom-width: 2px;
}

.table > thead > tr.border-double > th
{
    border-bottom-width: 4px;
    border-bottom-style: double;
}

.table > tbody > tr.border-dashed > td
{
    border-top-style: dashed;
}

.border-custom-color td
{
    border-top: inherit;
    border-bottom: inherit;
}

form
{
    /* .form-body{
        overflow: hidden;
    } */
}
form label
{
    color: #2b335e;
}
form .form-group
{
    margin-bottom: 1.5rem;
}
form .form-control
{
    color: #3b4781; 
    border: 1px solid #cacfe7;
}
form .form-control::-webkit-input-placeholder
{
    color: #aeb5da;
}
form .form-control:-moz-placeholder
{
    /* Firefox 18- */
    color: #aeb5da;
}
form .form-control::-moz-placeholder
{
    /* Firefox 19+ */
    color: #aeb5da;
}
form .form-control:-ms-input-placeholder
{
    color: #aeb5da;
}
form .form-section
{
    line-height: 3rem;

    margin-bottom: 20px;

    color: #2c303b;
    border-bottom: 1px solid #2c303b;
}
form .form-section i
{
    font-size: 20px;

    margin-right: 10px; 
    margin-left: 5px;
}
form .form-actions
{
    margin-top: 20px; 
    padding: 20px 0;

    border-top: 1px solid #d1d5ea;
}
form .form-actions.filled
{
    background-color: #f4f5fa;
}
form .form-actions.center
{
    text-align: center;
}
form .form-actions.right
{
    text-align: right;
}
form .form-actions.top
{
    margin-top: 0;
    margin-bottom: 20px; 

    border-top: 0;
    border-bottom: 1px solid #d1d5ea;
}
@media (max-width: 767.98px)
{
    form .form-actions .buttons-group
    {
        float: left !important;

        margin-bottom: 10px;
    }
}
@media (min-width: 576px)
{
    form.form-horizontal .form-group .label-control
    {
        text-align: right;
    }
}
form.row-separator .form-group
{
    margin: 0;

    border-bottom: 1px solid #e9ebf5;
}
form.row-separator .form-group .label-control
{
    padding-top: 1.5rem;
}
form.row-separator .form-group > div
{
    padding: 1.2rem;
    padding-right: 0;
}
form.row-separator .form-group.last
{
    border-bottom: 0;
}
form.form-bordered .form-group
{
    margin: 0;

    border-bottom: 1px solid #e9ebf5;
}
form.form-bordered .form-group .label-control
{
    padding-top: 1.5rem;
}
form.form-bordered .form-group > div
{
    padding: 1.2rem;
    padding-right: 0;

    border-left: 1px solid #e9ebf5;
}
form.form-bordered .form-group.last
{
    border-bottom: 0;
}
form.striped-rows .form-group
{
    margin: 0;
}
form.striped-rows .form-group .label-control
{
    padding-top: 1.5rem;
}
form.striped-rows .form-group > div
{
    padding: 1.2rem;
    padding-right: 0;
}
form.striped-rows .form-group:nth-child(even)
{
    background-color: #f4f5fa;
}
form.striped-labels .form-group
{
    margin: 0;
}
form.striped-labels .form-group .label-control
{
    padding-top: 1.5rem;
}
form.striped-labels .form-group > div
{
    padding: 1.2rem;
    padding-right: 0;

    background-color: white;
}
form.striped-labels .form-group:nth-child(even)
{
    background-color: #f4f5fa;
}
form .custom-control-indicator
{
    line-height: 1.05rem;

    top: .15rem;
    left: 0; 

    width: 1.1rem;
    height: 1.075rem;
}
form .custom-control-indicator + .custom-control-description
{
    margin-left: 1rem;
}

select.form-control
{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/*Bootstrap Enhance */
.custom-checkbox
{
    margin-bottom: 0;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(30, 159, 242, .5);
}

.custom-checkbox input:disabled:checked ~ .custom-control-indicator
{
    opacity: .5;
}

.custom-checkbox input:disabled ~ .custom-control-indicator
{
    cursor: not-allowed;
}

.custom-control-input > input:focus ~ .custom-control-indicator
{
    padding: .51rem;
}

.custom-control-indicator
{
    line-height: 1.05rem;

    top: .15rem; 

    width: 1.1rem;
    height: 1.075rem;
}
.custom-control-indicator + .custom-control-description
{
    margin-left: 1rem;
}

.form-control:disabled,
.form-control[readonly]
{
    background-color: #eceff1;
}

.input-group-addon
{
    border-color: #babfc7; 
    background-color: #eceff1;
}

.btn
{
    font-weight: 600;

    letter-spacing: .8px;
}
.btn.btn-square
{
    border-radius: 0;
}
.btn.btn-round
{
    border-radius: 2rem;
}
.btn:focus,
.btn.focus,
.btn:active,
.btn.active
{
    outline: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.btn.btn-icon i
{
    font-size: 1.1rem;
}
.btn.btn-icon.btn-pure
{
    border-color: transparent;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.btn.btn-icon.btn-pure i
{
    font-size: 1.25rem;
}
.btn.btn-social
{
    padding: .7rem .25rem .85rem 3.75rem;
}

.btn-min-width
{
    min-width: 8.5rem;
}

.btn-float
{
    padding: 15px;

    white-space: normal;

    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
            box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
}
.btn-float.btn-link
{
    padding: 10px;
}
.btn-float i
{
    top: 0; 

    display: block;

    margin: 0;
}
.btn-float.btn-float-lg i,
.btn-float.btn-float-lg span
{
    font-size: 2rem;
}
.btn-float.btn-float-lg i + span
{
    font-size: 1.1rem;
}
.btn-float > i + span
{
    display: block;

    margin-bottom: -6px; 
    padding-top: 10px;
}

.collapse-icon .card-header
{
    position: relative;
}

.collapse-icon [data-toggle='collapse']:after
{
    font-family: 'feather';

    position: absolute;
    top: 50%;
    right: 20px;

    margin-top: -8px;

    content: '\e929';
    -webkit-transition: all 300ms linear 0s;
       -moz-transition: all 300ms linear 0s;
         -o-transition: all 300ms linear 0s;
            transition: all 300ms linear 0s;
}

.collapse-icon [data-toggle='collapse']:before
{
    font-family: 'feather';

    position: absolute;
    top: 50%;
    right: 20px;

    margin-top: -8px;

    content: '\e927';
    -webkit-transition: all 300ms linear 0s;
       -moz-transition: all 300ms linear 0s;
         -o-transition: all 300ms linear 0s;
            transition: all 300ms linear 0s;
}

.collapse-icon.left .card-header .btn
{
    padding-left: 28px;
}
.collapse-icon.left .card-header .btn:before,
.collapse-icon.left .card-header .btn:after
{
    right: auto; 
    left: 20px;
}

.collapse-icon [aria-expanded='true']:before
{
    opacity: 0;
}

.collapse-icon [aria-expanded='false']:after
{
    opacity: 0;
}

.collapse-icon.accordion-icon-rotate [aria-expanded='true']:before
{
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}

.collapse-icon.accordion-icon-rotate [aria-expanded='false']:after
{
    -webkit-transform: rotate(-90deg);
       -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.dropdown.dropdown-demo
{
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

.dropdown .dropdown-menu
{
    min-width: 11rem;
    margin: -.1rem 0 0;

    -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
         -o-transition: all .2s linear;
            transition: all .2s linear;
    -webkit-transform: scale(1, 0);
       -moz-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
         -o-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
       -moz-transform-origin: top;
        -ms-transform-origin: top;
         -o-transform-origin: top;
            transform-origin: top;
    -webkit-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
       -moz-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
         -o-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
            animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1; 

    border: none;
    border-radius: .35rem;
    -webkit-box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25);
            box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25);

    -webkit-animation-fill-mode: forwards;
       -moz-animation-fill-mode: forwards;
         -o-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.dropdown .dropdown-menu .dropdown-header
{
    margin-top: 10px;
    padding: 10px 20px;

    text-transform: uppercase;

    color: #626e82;
}
.dropdown .dropdown-menu .dropdown-item
{
    width: 100%; 
    padding: 10px 20px;
}
.dropdown .dropdown-menu .dropdown-item:active a,
.dropdown .dropdown-menu .dropdown-item.active a
{
    color: #fff;
}

.dropdown .dropdown-divider
{
    border: 1px solid #e4e5ec;
}

.dropdown i
{
    margin-right: .5rem;
}

.dropdown .dropdown-menu
{
    min-width: 11rem;
    margin: -.1rem 0 0;

    -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
         -o-transition: all .2s linear;
            transition: all .2s linear;
    -webkit-transform: scale(1, 0);
       -moz-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
         -o-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
       -moz-transform-origin: top;
        -ms-transform-origin: top;
         -o-transform-origin: top;
            transform-origin: top;
    -webkit-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
       -moz-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
         -o-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
            animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1; 

    border: none;
    border-radius: .35rem;
    -webkit-box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25) !important;
            box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25) !important;

    -webkit-animation-fill-mode: forwards;
       -moz-animation-fill-mode: forwards;
         -o-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.dropdown .dropdown-menu .dropdown-header
{
    margin-top: 10px;
    padding: 10px 20px;

    text-transform: uppercase;

    color: #626e82;
}
.dropdown .dropdown-menu .dropdown-item
{
    width: 100%; 
    padding: 10px 20px;
}
.dropdown .dropdown-menu .dropdown-item:active a,
.dropdown .dropdown-menu .dropdown-item.active a
{
    color: #fff;
}

.show > .dropdown-menu
{
    z-index: 990; 

    display: block;

    -webkit-transform: scale(1, 1);
       -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
         -o-transform: scale(1, 1);
            transform: scale(1, 1);

    opacity: 1;
}

.dropdown-toggle::after
{
    font-family: 'LineAwesome';
    font-size: .8rem;

    position: relative;
    top: 0;
    right: 4px;

    margin: 0 .3em 0 0;
    padding: 0 2px 0 6px;

    content: '\f110' !important;
    vertical-align: 0; 

    border: none !important;
}

.dropdown-toggle.nav-hide-arrow::after
{
    display: none;
}

.dropdown-toggle:focus
{
    outline: 0;
}

.dropright .dropdown-toggle::after
{
    content: '\f112' !important;
}

.dropleft .dropdown-toggle::before
{
    font-family: 'LineAwesome';
    font-size: .8rem;

    position: relative;
    top: 0;
    left: 0;

    display: inline-block;

    margin: 0 .9em 0 0;
    padding: 0 2px 0 6px;

    content: '\f111' !important;
    vertical-align: 0; 

    border: none !important;
}

.navbar-horizontal .dropdown-menu .dropdown-submenu > a:after,
.navbar-horizontal .dropdown-menu .dropdown-submenu > button:after
{
    font-size: .85rem;

    right: 12px;
}

.navbar-horizontal .dropdown-toggle::after
{
    top: 12px !important;
}

.navbar-horizontal .nav .dropdown-divider
{
    margin: .5rem 0 .5rem -1rem;
}

.dropdown-menu-right
{
    right: 0;
}

.dropdown-menu
{
    -webkit-box-shadow: none;
            box-shadow: none;
}
.dropdown-menu .dropdown-submenu
{
    position: relative;
}
.dropdown-menu .dropdown-submenu:hover .dropdown-menu
{
    display: block;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu
{
    top: 0;
    left: 100%;

    margin-top: -6px;

    background: transparent;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu.open-left
{
    right: 100%; 
    left: auto;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu .arrow_box
{
    padding: 10px 0;

    border-radius: .35rem; 
    background: #fff;
    -webkit-box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25);
            box-shadow: 0 0 15px 1px rgba(64, 70, 74, .25);
}
.dropdown-menu .dropdown-submenu > .dropdown-menu .arrow_box li
{
    padding: 0 8px;
}
.dropdown-menu .dropdown-submenu > a:after,
.dropdown-menu .dropdown-submenu > button:after
{
    font-family: 'LineAwesome';
    font-size: 1rem;

    position: absolute; 
    right: 15px;

    float: right;

    content: '\f112' !important;

    border: none;
}
.dropdown-menu .dropdown-submenu :hover > a:after,
.dropdown-menu .dropdown-submenu :hover > button:after
{
    border-left-color: #555;
}
.dropdown-menu .dropdown-submenu .pull-left
{
    float: none;
}
.dropdown-menu .dropdown-submenu .pull-left > .dropdown-menu
{
    left: -100%;

    margin-left: 10px;
}

.dropdown-header
{
    text-transform: uppercase;
}
.dropdown-header.highlight
{
    text-decoration: none;

    color: #2b2d2f;
    background-color: #f5f5f5;
}

.btn-secondary ~ .dropdown-menu .dropdown-item.active
{
    background-color: #ccc;
}

.btn-primary ~ .dropdown-menu .dropdown-item.active
{
    background-color: #6967ce;
}

.btn-success ~ .dropdown-menu .dropdown-item.active
{
    background-color: #5ed84f;
}

.btn-danger ~ .dropdown-menu .dropdown-item.active
{
    background-color: #fa626b;
}

.btn-info ~ .dropdown-menu .dropdown-item.active
{
    background-color: #28afd0;
}

.btn-warning ~ .dropdown-menu .dropdown-item.active
{
    background-color: #fdb901;
}

.dropdown-menu.arrow
{
    margin-top: 12px;
}
.dropdown-menu.arrow:after,
.dropdown-menu.arrow:before
{
    position: absolute;
    left: 10px;

    display: inline-block;

    width: 0;
    height: 0;

    content: '';

    border: 7px solid transparent;
    border-top-width: 0;
}
.dropdown-menu.arrow:before
{
    top: -7px;

    border-bottom-color: #b3b3b3;
}
.dropdown-menu.arrow:after
{
    top: -6px;

    border-bottom-color: #fff;
}

.dropdown-menu .arrow-left
{
    margin-left: 7px !important;
}
.dropdown-menu .arrow-left:after,
.dropdown-menu .arrow-left:before
{
    position: absolute;
    top: 10px;

    display: inline-block;

    width: 0;
    height: 0;

    content: '';

    border: 7px solid transparent;
    border-left-width: 0;
}
.dropdown-menu .arrow-left:before
{
    left: -8px;

    border-right-color: #b3b3b3;
}
.dropdown-menu .arrow-left:after
{
    left: -7px;

    border-right-color: #fff;
}

.dropdown-menu .arrow-right
{
    margin-right: 7px !important;
}
.dropdown-menu .arrow-right:after,
.dropdown-menu .arrow-right:before
{
    position: absolute;
    top: 10px;

    display: inline-block;

    width: 0;
    height: 0;

    content: '';

    border: 7px solid transparent;
    border-right-width: 0;
}
.dropdown-menu .arrow-right:before
{
    right: -7px;

    border-left-color: #b3b3b3;
}
.dropdown-menu .arrow-right:after
{
    right: -6px;

    border-left-color: #fff;
}

.dropup .dropdown-toggle::after
{
    content: '\f113' !important;
}

.dropup .dropdown-menu
{
    top: auto;
}
.dropup .dropdown-menu.arrow
{
    margin-bottom: 12px;
}
.dropup .dropdown-menu.arrow:after,
.dropup .dropdown-menu.arrow:before
{
    top: auto;

    border-top-width: 7px;
    border-bottom-width: 0;
}
.dropup .dropdown-menu.arrow:before
{
    bottom: -8px;

    border-top-color: #b3b3b3;
}
.dropup .dropdown-menu.arrow:after
{
    bottom: -6px;

    border-top-color: #fff;
}

.dropup .dropdown-submenu .dropdown-menu
{
    top: 0; 
    bottom: auto;
}

.dropdown-item.buttons-columnVisibility.active
{
    color: #000;
}

.drilldown-menu .dropdown-item.active.hover,
.drilldown-menu .dropdown-item.active:hover,
.drilldown-menu .dropdown-item:active.hover,
.drilldown-menu .dropdown-item:active:hover
{
    color: #fff;
}

@-webkit-keyframes menu-header-fade-in
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-moz-keyframes menu-header-fade-in
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-o-keyframes menu-header-fade-in
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@keyframes menu-header-fade-in
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-webkit-keyframes menu-header-move-up
{
    from
    {
        margin-top: 10px;
    }
    to
    {
        margin-top: 0;
    }
}

@-moz-keyframes menu-header-move-up
{
    from
    {
        margin-top: 10px;
    }
    to
    {
        margin-top: 0;
    }
}

@-o-keyframes menu-header-move-up
{
    from
    {
        margin-top: 10px;
    }
    to
    {
        margin-top: 0;
    }
}

@keyframes menu-header-move-up
{
    from
    {
        margin-top: 10px;
    }
    to
    {
        margin-top: 0;
    }
}

/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Creative Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.0
    Author: ThemeSelection
    Author URL: https://themeforest.net/user/themeselect
================================================================================================*/
.navbar-dark .hamburger-inner,
.navbar-dark .hamburger-inner::before,
.navbar-dark .hamburger-inner::after
{
    background-color: #fff;
}

.navbar-header
{
    position: relative;
    z-index: 9;

    width: 260px;
    height: 100%;
    height: 5rem;
    padding: 0rem .85rem;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;
}
.navbar-header ul.navbar-nav
{
    overflow: hidden;
}
.navbar-header .navbar-brand
{
    margin-right: 0; 
    padding: 17px 0;
}
.navbar-header .navbar-brand .brand-logo
{
    width: 36px;
}
.navbar-header .navbar-brand .brand-text
{
    font-size: 1.4rem;
    font-weight: 700;

    display: inline;

    padding-left: 6px;

    vertical-align: middle;
}
.navbar-header .close-navbar
{
    padding: 1.5rem 0rem;
}
.navbar-header.navbar-header-menu-dark
{
    background: #2c303b;
}
.navbar-header.navbar-header-menu-dark .brand-text
{
    color: #fff;
}
.navbar-header.navbar-header-menu-light
{
    background: #fff;
}
.navbar-header.navbar-header-menu-light .brand-text
{
    color: #464855;
}

.header-navbar
{
    font-family: 'Comfortaa', cursive, 'Times New Roman', Times, serif;

    min-height: 5rem;
    padding: 0;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;
    /* For sm screen*/
}
.header-navbar .navbar-wrapper
{
    width: 100%;
}
.header-navbar.navbar-border
{
    border-bottom: 1px solid #e4e7ed;
}
.header-navbar.navbar-dark.navbar-border
{
    border-bottom: 1px solid #8596b5;
}
.header-navbar.navbar-shadow
{
    -webkit-box-shadow: 0 2px 30px 2px rgba(0, 0, 0, .1);
            box-shadow: 0 2px 30px 2px rgba(0, 0, 0, .1);
}
.header-navbar.navbar-without-dd-arrow .nav-link.dropdown-toggle::after
{
    display: none;
}
.header-navbar.navbar-brand-center .navbar-header
{
    position: absolute;
    z-index: 999; 
    left: 50%;

    width: 210px;
    margin: 0;
    padding: 0;

    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}
.header-navbar .navbar-container
{
    height: 5rem;
    padding: 0rem 18px;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;

    background: inherit;
}
.header-navbar .navbar-container .show > .dropdown-menu .arrow_box_right
{
    position: relative;
}
.header-navbar .navbar-container .show > .dropdown-menu .arrow_box_right:after
{
    position: absolute;
    right: 1rem;
    bottom: 100%;

    width: 0;
    height: 0;
    margin-left: -16px; 

    content: ' ';
    pointer-events: none;

    border: solid transparent;
    border-width: 16px;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
}
.header-navbar .navbar-container .show > .dropdown-menu .arrow_box
{
    position: relative;
}
.header-navbar .navbar-container .show > .dropdown-menu .arrow_box:after
{
    position: absolute;
    bottom: 100%;
    left: 1.8rem;

    width: 0;
    height: 0;
    margin-left: -16px; 

    content: ' ';
    pointer-events: none;

    border: solid transparent;
    border-width: 16px;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
}
.header-navbar .navbar-container .dropdown-submenu.show .arrow_box
{
    position: relative;
}
.header-navbar .navbar-container .dropdown-submenu.show .arrow_box:after
{
    position: absolute;
    top: 7px;
    bottom: 100%;

    width: 0;
    height: 0;
    margin-left: -41px;

    content: ' ';
    pointer-events: none;

    border: solid transparent;
    border-top: 10px solid transparent;
    border-right: 13px solid #fff; 
    border-bottom: 10px solid transparent;
}
.header-navbar .navbar-container.navbar-shadow
{
    -webkit-box-shadow: 0 2px 30px 2px rgba(0, 0, 0, .1);
            box-shadow: 0 2px 30px 2px rgba(0, 0, 0, .1);
}
.header-navbar .navbar-container ul.nav li > a.nav-link
{
    font-size: 1.15rem; 

    padding: 1.9rem 1rem;
}
.header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon
{
    margin-right: .5rem;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link
{
    line-height: 23px; 

    padding: 1.2rem 1rem;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name
{
    display: inline-block;

    margin-bottom: .4rem;
    margin-left: .2rem;
}
.header-navbar .navbar-container ul.nav li a.menu-toggle
{
    padding: 1.7rem 1rem 1.6rem 1rem;
}
.header-navbar .navbar-container ul.nav li a.menu-toggle i
{
    font-size: 1.6rem;
}
.header-navbar .navbar-container ul.nav li a.nav-link-label
{
    padding: 1.7rem 1rem 1.6rem 1rem;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .avatar
{
    width: 36px; 
    margin-right: .5rem;
}
.header-navbar .navbar-container ul.nav li a.nav-link-search,
.header-navbar .navbar-container ul.nav li a.nav-link-expand
{
    padding: 1.7rem 1rem 1.7rem 1rem;
}
.header-navbar .navbar-container ul.nav li div.input-group
{
    padding: .7rem 1rem;
}
.header-navbar .navbar-container ul.nav li i.ficon
{
    font-size: 1.5rem;
}
.header-navbar .navbar-container ul.nav li .media-list
{
    max-height: 18.2rem;
}
.header-navbar .navbar-container ul.nav li .scrollable-container
{
    position: relative;
}
.header-navbar .navbar-container ul.nav li input.form-control:focus
{
    -webkit-box-shadow: 0 0 8px #fff;
            box-shadow: 0 0 8px #fff;
}
.header-navbar .navbar-container .dropdown-menu-media
{
    width: 24rem;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .media
{
    padding: 1rem;

    border: none;
    border-bottom: 1px solid #e4e5ec;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header
{
    border-bottom: 1px solid #e4e5ec;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer a
{
    padding: .3rem;

    border-top: 1px solid #e4e5ec;
}
.header-navbar.navbar-without-menu .navbar-container
{
    margin-left: 0;
}
.header-navbar .nav-item + .nav-item
{
    margin-left: 0rem;
}
@media (max-width: 767.98px)
{
    .header-navbar .navbar-header
    {
        width: 100% !important;
        padding: .5rem 1rem;
    }
    .header-navbar .navbar-header .menu-toggle
    {
        position: relative; 
        top: 2px;
    }
    .header-navbar .navbar-header .open-navbar-container i
    {
        font-size: 1.8rem;
    }
    .header-navbar .navbar-header .navbar-brand
    {
        position: absolute;
        top: 0;
        left: 50%;

        -webkit-transform: translate(-50%, 0);
           -moz-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
             -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
    }
    .header-navbar.navbar-with-menu .navbar-container
    {
        display: table;

        width: 100%;
        height: auto; 
        margin: 0;
        padding: 0rem 1rem;

        background: #fff;
    }
}

.navbar-light
{
    background: #fff;
}
.navbar-light.navbar-horizontal
{
    background: #fff;
}
.navbar-light .navbar-nav > li
{
    line-height: .9;

    z-index: 1051;
}
.navbar-light .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-light .navbar-nav .disabled.nav-link
{
    color: #6b6f80;
}

.navbar-dark
{
    background: #2c303b;
}
.navbar-dark.navbar-horizontal
{
    background: #2c303b;
}
.navbar-dark .nav-search .form-control,
.navbar-dark .nav-search .btn-secondary
{
    color: #fff;
    background: #2c303b;
}
.navbar-dark .navbar-brand .brand-text
{
    color: #fff !important;
}
.navbar-dark .navbar-nav > li
{
    line-height: .9;

    z-index: 1051;
}
.navbar-dark .navbar-nav .active.nav-link
{
    background-color: rgba(255, 255, 255, .05);
}
.navbar-dark .navbar-nav .disabled.nav-link
{
    color: #98a4b8;
}

.navbar-semi-dark
{
    background: #fff;
}
.navbar-semi-dark .navbar-header
{
    background: #2c303b;
}
.navbar-semi-dark .navbar-header .brand-text
{
    color: #fff;
}
.navbar-semi-dark .navbar-nav > li
{
    line-height: .9;

    z-index: 1051;
}
.navbar-semi-dark .navbar-nav .nav-link
{
    color: #fff;
}
.navbar-semi-dark .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-semi-dark .navbar-nav .disabled.nav-link
{
    color: #6b6f80;
}

.navbar-semi-light
{
    background: #6b6f80;
}
.navbar-semi-light .navbar-header
{
    background: #fff;
}
.navbar-semi-light .navbar-header .brand-text
{
    color: #464855;
}
.navbar-semi-light .navbar-nav > li
{
    line-height: .9;

    z-index: 1051;
}
.navbar-semi-light .navbar-nav .nav-link
{
    color: #fff;
}
.navbar-semi-light .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-semi-light .navbar-nav .disabled.nav-link
{
    color: #6b6f80;
}

.mega-dropdown
{
    position: static !important;
}
.mega-dropdown .dropdown-menu
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
}

.navigation-main .mega-dropdown-menu,
.navbar-nav .mega-dropdown-menu
{
    font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

    z-index: 9999; 

    width: -webkit-calc(100% - 50px);
    width:    -moz-calc(100% - 50px);
    width:         calc(100% - 50px);
    /* fallback if needed */
    margin: 0 25px;
    padding: .5rem 0;

    -webkit-box-shadow: none;
    -webkit-box-shadow: 0 5px 75px 2px rgba(64, 70, 74, .2);
            box-shadow: none;
            box-shadow: 0 5px 75px 2px rgba(64, 70, 74, .2);
}
.navigation-main .mega-dropdown-menu ul,
.navbar-nav .mega-dropdown-menu ul
{
    margin: 0; 
    padding: 0;
}
.navigation-main .mega-dropdown-menu ul li,
.navbar-nav .mega-dropdown-menu ul li
{
    list-style: none;
}
.navigation-main .mega-dropdown-menu ul li a,
.navbar-nav .mega-dropdown-menu ul li a
{
    display: block;

    color: #464855;
}
.navigation-main .mega-dropdown-menu ul li a .children-in,
.navbar-nav .mega-dropdown-menu ul li a .children-in
{
    float: right;
}
.navigation-main .mega-dropdown-menu ul li a:hover,
.navbar-nav .mega-dropdown-menu ul li a:hover
{
    text-decoration: none;

    color: #6b6f80;
}
.navigation-main .mega-dropdown-menu ul li a:focus,
.navbar-nav .mega-dropdown-menu ul li a:focus
{
    text-decoration: none;

    color: #6b6f80;
}
.navigation-main .mega-dropdown-menu ul a.dropdown-item,
.navbar-nav .mega-dropdown-menu ul a.dropdown-item
{
    padding: 10px;
}
.navigation-main .mega-dropdown-menu p.accordion-text,
.navbar-nav .mega-dropdown-menu p.accordion-text
{
    line-height: 1.45;
}
.navigation-main .mega-dropdown-menu .dropdown-menu-header,
.navbar-nav .mega-dropdown-menu .dropdown-menu-header
{
    padding: .8rem 0;

    border-bottom: 1px solid #e4e5ec;
}
.navigation-main .mega-dropdown-menu h6 i.la,
.navbar-nav .mega-dropdown-menu h6 i.la
{
    font-size: 1.1rem;
}

.horizontal-top-icon-menu .mega-dropdown-menu
{
    margin: 0 25px !important;
}

@media (min-width: 576px)
{
    .container .mega-dropdown-menu
    {
        max-width: 540px;
        margin: 0 -webkit-calc((100% - 540px)/2);
        margin: 0 -moz-calc((100% - 540px)/2);
        margin: 0 calc((100% - 540px)/2);
    }
}

@media (min-width: 768px)
{
    .container .mega-dropdown-menu
    {
        max-width: 720px;
        margin: 0 -webkit-calc((100% - 720px)/2);
        margin: 0 -moz-calc((100% - 720px)/2);
        margin: 0 calc((100% - 720px)/2);
    }
}

@media (min-width: 992px)
{
    .container .mega-dropdown-menu
    {
        max-width: 960px;
        margin: 0 -webkit-calc((100% - 960px)/2);
        margin: 0 -moz-calc((100% - 960px)/2);
        margin: 0 calc((100% - 960px)/2);
    }
}

@media (min-width: 1200px)
{
    .container .mega-dropdown-menu
    {
        max-width: 1140px;
        margin: 0 -webkit-calc((100% - 1140px)/2);
        margin: 0 -moz-calc((100% - 1140px)/2);
        margin: 0 calc((100% - 1140px)/2);
    }
}

@-moz-document url-prefix()
{
    ul li a .children-in
    {
        position: relative;
        top: -14px; 
        right: 3px;
    }
}

.menu-list
{
    overflow: hidden; 

    padding: 0;

    list-style: none;
}

.menu-panel
{
    padding: 0;
}

.sliding-menu
{
    position: relative; 

    overflow: hidden;
}
.sliding-menu ul
{
    float: left;

    margin: 0;
    padding: 0;
}
.sliding-menu ul li
{
    margin: 0; 

    list-style: none;
}

.navbar-menu-icon
{
    position: relative;

    width: 24px;
    margin: 16px 2px !important;
    margin: 0 auto;

    cursor: pointer; 
    -webkit-transition: .5s ease-in-out;
       -moz-transition: .5s ease-in-out;
         -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}

.navbar-menu-icon span
{
    position: absolute;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    -webkit-transition: .25s ease-in-out;
       -moz-transition: .25s ease-in-out;
         -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    opacity: 1;
    border-radius: 9px;
    background: #6b6f80;
}

.navbar-menu-icon span:nth-child(1)
{
    top: 0;
}

.navbar-menu-icon span:nth-child(2)
{
    top: 10px;
}

.navbar-menu-icon span:nth-child(3)
{
    top: 20px;
}

.navbar-menu-icon.show span:nth-child(1)
{
    top: 10px;

    -webkit-transform: rotate(135deg);
       -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
         -o-transform: rotate(135deg);
            transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2)
{
    left: -60px; 

    opacity: 0;
}

.navbar-menu-icon.show span:nth-child(3)
{
    top: 10px;

    -webkit-transform: rotate(-135deg);
       -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
         -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
}

@media (max-width: 767.98px)
{
    .header-navbar .navbar-nav .show
    {
        position: static;
    }
    .header-navbar .navbar-nav .open-navbar-container
    {
        padding-top: .625rem;
    }
    .header-navbar .navbar-container .show .dropdown-menu
    {
        right: 0;
        left: 0 !important;

        float: none;
        overflow-x: hidden;
        overflow-y: scroll; 

        width: auto;
        max-height: 400px;
        margin-top: 0;
    }
    .header-navbar .navbar-container ul.nav li.nav-item i
    {
        margin-right: .2rem;
    }
    .header-navbar .navbar-container ul.nav li > a.nav-link
    {
        padding: 1.5rem .4rem;
    }
    .header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon
    {
        margin-right: .2rem;
    }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link
    {
        padding: .8rem .6rem;
    }
    .header-navbar .navbar-container ul.nav li a.nav-link-label
    {
        padding: 1.5rem .6rem;
    }
    .header-navbar .navbar-container ul.nav li a.nav-link-search,
    .header-navbar .navbar-container ul.nav li a.nav-link-expand
    {
        padding: 1.4rem 1rem 1rem 1rem;
    }
    .header-navbar .navbar-container ul.nav li .dropdown-toggle::after
    {
        margin-right: 0;
        margin-left: -2px;
    }
    .navbar-dark .hamburger-inner,
    .navbar-dark .hamburger-inner::before,
    .navbar-dark .hamburger-inner::after,
    .navbar-semi-dark .hamburger-inner,
    .navbar-semi-dark .hamburger-inner::before,
    .navbar-semi-dark .hamburger-inner::after
    {
        background-color: #fff;
    }
    .navbar-dark .navbar-header .navbar-nav .nav-link,
    .navbar-semi-dark .navbar-header .navbar-nav .nav-link
    {
        color: #fff;
    }
    .navbar-light .navbar-header .navbar-nav .nav-link,
    .navbar-semi-light .navbar-header .navbar-nav .nav-link
    {
        color: #2c303b;
    }
    .navbar-light .navbar-nav .nav-link,
    .navbar-semi-light .navbar-nav .nav-link
    {
        color: #fff;
    }
}

@media (min-width: 576px)
{
    .search-box input
    {
        width: 240px;
    }
    .search-box .form-control-position
    {
        cursor: pointer;
    }
}

.headroom
{
    -webkit-transition: -webkit-transform 200ms linear;
       -moz-transition:         transform 200ms linear, -moz-transform 200ms linear;
         -o-transition:      -o-transform 200ms linear;
            transition: -webkit-transform 200ms linear;
            transition:         transform 200ms linear;
            transition:         transform 200ms linear, -webkit-transform 200ms linear, -moz-transform 200ms linear, -o-transform 200ms linear; 

    will-change: transform;
}

.headroom--pinned-top
{
    -webkit-transform: translateY(0%);
       -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
         -o-transform: translateY(0%);
            transform: translateY(0%);
}

.headroom--unpinned-top
{
    -webkit-transform: translateY(-100%);
       -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
         -o-transform: translateY(-100%);
            transform: translateY(-100%);
}

.headroom--pinned-bottom
{
    -webkit-transform: translateY(0%);
       -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
         -o-transform: translateY(0%);
            transform: translateY(0%);
}

.headroom--unpinned-bottom
{
    -webkit-transform: translateY(100%);
       -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
         -o-transform: translateY(100%);
            transform: translateY(100%);
}

@media (max-width: 1199.98px)
{
    .header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon
    {
        margin-right: .2rem;
    }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link
    {
        padding: .8rem .6rem;
    }
    .header-navbar .navbar-container ul.nav li .dropdown-toggle::after
    {
        margin-right: 0;
        margin-left: -2px;
    }
}

@media (max-width: 1199.98px)
{
    .header-navbar .navbar-container ul.nav li .selected-language
    {
        display: none;
    }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name
    {
        display: none;
    }
}

@media (max-width: 767px)
{
    #navbar-mobile.navbar-collapse .navbar-nav
    {
        margin: 0;

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item
    {
        float: left;
    }
}

/* ----------- iPhone 5, 5S  iPhone 6----------- */
/* Landscape */
@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)
{
    .header-navbar .navbar-container .show .dropdown-menu
    {
        max-height: 180px;
    }
}

/* ----------- iPhone 6+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)
{
    .header-navbar .navbar-container .show .dropdown-menu
    {
        max-height: 280px;
    }
}

.card-group,
.card-deck
{
    margin-bottom: .75rem;
}

.card
{
    margin-bottom: 1.875rem;

    border: none;
    -webkit-box-shadow: 0 1px 15px 1px rgba(62, 57, 107, .07);
            box-shadow: 0 1px 15px 1px rgba(62, 57, 107, .07);
}
.card .card
{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.card .card-title
{
    font-size: 1.12rem; 
    font-weight: 700;

    letter-spacing: .05rem;
}
.card .card-bordered
{
    border: 1px solid rgba(0, 0, 0, 0);
}
.card.card-fullscreen
{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    overflow: auto; 

    width: 100% !important;
    height: 100% !important;
}
.card .card-body[class*='border-bottom-']
{
    border-bottom-width: 2px !important;
}
.card .card-img-overlay.bg-overlay
{
    background: rgba(0, 0, 0, .45);
}
.card .card-img-overlay .text-muted
{
    color: #464855 !important;
}
.card.card-minimal
{
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.card.crypto-card-3 .card-body:before
{
    font-family: 'cryptocoins';
    font-size: 10rem;

    position: absolute;
    right: 0; 
    bottom: -32px;

    color: rgba(255, 255, 255, .15);
}

.card-moved .card,
.card-moved .card-header
{
    color: #fff; 
    background-color: #fdb901;
}

.handle,
.titleArea
{
    cursor: move;
}

.card-subtitle
{
    padding-bottom: 1rem;
}

.card-header
{
    border-bottom: none;
}
.card-header .card-title
{
    margin-bottom: 0;
}
.card-header .heading-elements,
.card-header .heading-elements-toggle
{
    position: absolute;
    top: 20px;
    right: 20px; 

    background-color: inherit;
}
.card-header .heading-elements.heading-top-elements .page-item,
.card-header .heading-elements-toggle.heading-top-elements .page-item
{
    display: inline-block;
}
.card-header .heading-elements a,
.card-header .heading-elements-toggle a
{
    padding: 0 8px;
}
.card-header .heading-elements a.btn,
.card-header .heading-elements-toggle a.btn
{
    padding-top: 6px;
    padding-bottom: 6px;
}

.card-footer
{
    border-top: 1px solid #eee;
}

.text-white .card-text
{
    color: white;
}

.text-white .card-img-overlay .text-muted
{
    color: #fff !important;
}

.text-white code
{
    background-color: white;
}

.text-white .heading-elements i
{
    color: #fff;
}

.card-head-inverse
{
    color: #fff;
}
.card-head-inverse .heading-elements i
{
    color: #fff;
}

.card-transparent
{
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.card-image-position-right
{
    position: absolute;
    top: -27px; 

    margin-left: 50px;
}

.card-image-position-right-mid
{
    position: absolute;
    top: 23px;

    width: 250px; 
    margin-left: 280px;
}

.card-font-style-black
{
    font-size: 90px;
    font-weight: 900;

    margin-top: -12px; 

    color: #fff52;
}

.card-font-style-white
{
    font-size: 72px;
    font-weight: 900;

    margin-top: -10px;
}

.background-round
{
    padding: 8px;

    border-radius: 50%; 
    background-color: rgba(0, 0, 0, .18);
}

.card-accordion .card .card,
.card-collapse .card .card
{
    margin-bottom: 0;
}

.card-accordion .card-header,
.card-collapse .card-header
{
    position: relative;
}
.card-accordion .card-header .btn,
.card-collapse .card-header .btn
{
    padding: 0;
}
.card-accordion .card-header:after,
.card-collapse .card-header:after
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background-color: #e7e9f1;
}

@media (max-width: 991.98px)
{
    .heading-elements
    {
        text-align: center;
    }
    .heading-elements .list-inline
    {
        display: none;
    }
    .heading-elements.visible
    {
        position: absolute;
        z-index: 997;
        top: 100%;
        top: 50px;
        left: 0;

        display: block;

        width: 100%; 
        height: auto;
        margin-top: 0;
        padding: 10px;

        border: 1px solid #eee;
    }
    .heading-elements.visible .list-inline
    {
        display: block;
    }
}

@media (min-width: 992px)
{
    .heading-elements-toggle
    {
        display: none;
    }
}

.breadcrumb
{
    font-family: 'Comfortaa', cursive, 'Times New Roman', Times, serif;
    font-size: 1rem;

    padding: 0 0 .75rem 0rem; 

    background-color: transparent;
}
.breadcrumb > li + li::before
{
    padding-right: .6rem;
    padding-left: .6rem;
}
.breadcrumb .breadcrumb-item.active
{
    color: #fff;
}
.breadcrumb .breadcrumb-item a
{
    color: rgba(255, 255, 255, .8);
}
.breadcrumb .breadcrumb-item a:hover
{
    color: #fff;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item
{
    padding-left: 0;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before
{
    content: '-';
}

/* Custom scss for page headers style */
.breadcrumbs-right .breadcrumb
{
    float: right;
}

.breadcrumbs-top .breadcrumb
{
    margin: 0;
    padding: 0;
}

.breadcrumb-new .content-header-title
{
    margin: 0 24px 0 0;
    padding: 6px 24px 8px 0;

    border-right: 1px solid rgba(255, 255, 255, .3);
}

@media (max-width: 767.98px)
{
    .breadcrumb
    {
        font-size: .85rem;
    }
    .breadcrumbs-top .breadcrumb
    {
        padding-bottom: .5rem;
    }
}

.pagination
{
    margin-top: 1rem;

    white-space: nowrap;
}
.pagination .page-link
{
    color: #6967ce;
    border: 1px solid #babfc7;
}

.pager
{
    margin: 1.45 0;
    padding-left: 0;

    list-style: none;

    text-align: center;
}
.pager li
{
    display: inline;
}
.pager li > a,
.pager li > span
{
    display: inline-block;

    padding: 5px 14px;

    border: 1px solid #626e82;
    border-radius: .25rem; 
    background-color: #fff;
}
.pager li > a:hover,
.pager li > a:focus
{
    text-decoration: none;

    background-color: #f9fafd;
}
.pager.pager-round li a
{
    border-radius: 2rem;
}
.pager .next > a,
.pager .next > span
{
    float: right;
}
.pager .previous > a,
.pager .previous > span
{
    float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span
{
    color: #6b6f80;
    background-color: #fff;
}
.pager [class*='text-'] .pager
{
    text-align: inherit;
}

.badge
{
    font-weight: 400; 

    color: #fff;
}
.badge[class*='badge-'] [class*='icon-']
{
    line-height: 1;
}
.badge[class*='badge-'] [class*='icon-'] ~ span
{
    position: relative;
    bottom: 2px;

    padding: 0 5px;
}
.badge[class*='badge-'] span
{
    position: relative;
    bottom: 2px;

    padding: 0 5px;
}
.badge[class*='badge-'] a
{
    color: #fff;
}
.badge[class*='badge-'] .dropdown-toggle::after,
.badge[class*='badge-'].dropdown-toggle::after
{
    font-size: 1rem; 

    position: relative;
    top: -2px;
    right: 3px;
}
.badge[class*='badge-'] .dropdown-menu a
{
    color: #555;
}
.badge.badge-border
{
    font-size: 85%; 

    border-width: 2px !important;
    background-color: transparent;
}
.badge.badge.float-right
{
    margin-top: .2rem; 
    margin-left: .5rem;
}

.badge-xl
{
    font-size: 1.8rem;
}

.badge-lg
{
    font-size: 1.2rem;
}

.badge-md
{
    font-size: 1rem;
}

.badge-sm
{
    font-size: .8rem;
}

.badge-secondary
{
    background-color: #6b6f80;
}
.badge-secondary.badge-glow
{
    -webkit-box-shadow: 0 0 10px #6b6f80;
            box-shadow: 0 0 10px #6b6f80;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus
{
    background-color: #545764;
}

.badge-primary
{
    background-color: #6967ce;
}
.badge-primary.badge-glow
{
    -webkit-box-shadow: 0 0 10px #6967ce;
            box-shadow: 0 0 10px #6967ce;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus
{
    background-color: #4340c2;
}

.badge-success
{
    background-color: #5ed84f;
}
.badge-success.badge-glow
{
    -webkit-box-shadow: 0 0 10px #5ed84f;
            box-shadow: 0 0 10px #5ed84f;
}
.badge-success[href]:hover,
.badge-success[href]:focus
{
    background-color: #3dc82c;
}

.badge-info
{
    background-color: #28afd0;
}
.badge-info.badge-glow
{
    -webkit-box-shadow: 0 0 10px #28afd0;
            box-shadow: 0 0 10px #28afd0;
}
.badge-info[href]:hover,
.badge-info[href]:focus
{
    background-color: #208ba5;
}

.badge-warning
{
    background-color: #fdb901;
}
.badge-warning.badge-glow
{
    -webkit-box-shadow: 0 0 10px #fdb901;
            box-shadow: 0 0 10px #fdb901;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus
{
    background-color: #ca9401;
}

.badge-danger
{
    background-color: #fa626b;
}
.badge-danger.badge-glow
{
    -webkit-box-shadow: 0 0 10px #fa626b;
            box-shadow: 0 0 10px #fa626b;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus
{
    background-color: #f8313c;
}

.badge-up
{
    position: absolute;
    top: 10px;
    right: 6px;
}

.badge-icon i
{
    font-size: 100%;

    margin-right: 5px;
}

.form-control + .block-tag
{
    display: block;
}

.block-tag
{
    margin-top: .5rem;
}

.block-area
{
    padding: .24rem .3rem;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted
{
    font-size: 100%;
    font-weight: 400;

    padding: 0; 

    color: #6967ce;
}

.token.punctuation
{
    color: #6967ce;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted
{
    color: #28afd0;
}

.token.atrule,
.token.attr-value,
.token.keyword
{
    color: #fa626b;
}

code[class*='language-'],
pre[class*='language-']
{
    color: #6b6f80;
}

.nav
{
    border-radius: .25rem;
}
.nav.wrap-border
{
    border: 1px solid #ddd;
}
.nav.wrap-border li.nav-header
{
    margin: 0 .5rem;
}
.nav.no-hover-bg .nav-item:hover
{
    background-color: transparent;
}
.nav .dropdown .dropdown-item.active
{
    color: #55595c; 
    background-color: transparent;
}
.nav .dropdown .dropdown-item.active:focus
{
    color: #55595c; 
    background-color: transparent;
}
.nav.square-border
{
    border-radius: 0;
}
.nav.square-border .nav-item .nav-link.active
{
    border-radius: 0;
}
.nav .nav-item .nav-link
{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;
}
.nav .nav-item i
{
    -webkit-align-self: center;
    -ms-flex-item-align: center;
            align-self: center;
}
.nav .dropdown-divider
{
    margin: .5rem 0;
}
.nav .modern-nav-toggle
{
    padding: 25px 0;
}
.nav.nav-pills .nav-item .nav-link
{
    line-height: normal;

    padding: 1rem 1.5rem;

    border-radius: 2rem;
}
.nav.nav-pills .nav-item .nav-link i
{
    font-size: 1.4rem;
}
.nav.nav-pills .nav-item .nav-link.active
{
    border-radius: 2rem;
}
.nav.nav-pills .nav-item .nav-link i.float-right:before
{
    position: relative;
    top: 7px;
    left: 7px;
}
.nav.nav-pills .nav-item .nav-link .badge.float-right
{
    position: relative;
    top: 1px;
    left: 3px;
}
.nav.nav-pills .nav-item .nav-link.dropdown-toggle::after
{
    top: -1px;
}
.nav.nav-pills.nav-justified
{
    width: 100%;
}
.nav.nav-pills.nav-justified .nav-item
{
    float: none;
}
.nav.nav-pills.nav-justified .nav-link
{
    margin-bottom: 5px; 

    text-align: center;
}
.nav.nav-pills.nav-justified > .dropdown .dropdown-menu
{
    top: auto;
    left: auto;
}
@media (min-width: 576px)
{
    .nav.nav-pills.nav-justified .nav-item
    {
        display: table-cell;

        width: 1%;
    }
    .nav.nav-pills.nav-justified .nav-link
    {
        margin-bottom: 0;
    }
}
.nav.nav-pills.nav-justified .nav-link
{
    margin-right: 0;

    border-radius: .25rem;
}
@media (min-width: 576px)
{
    .nav.nav-pills.nav-justified .nav-link.active,
    .nav.nav-pills.nav-justified .nav-link.active:hover,
    .nav.nav-pills.nav-justified .nav-link.active:focus
    {
        border-bottom-color: transparent;
    }
}
.nav.nav-pills.nav-justified .nav-link
{
    display: block;

    border-radius: 2rem;
}
.nav.nav-pills.nav-justified .nav-link.active
{
    border: none;
}
.nav.nav-pills.nav-justified .nav-link.active:hover,
.nav.nav-pills.nav-justified .nav-link.active:focus
{
    border: none;
}
.nav.nav-pills.nav-pill-with-active-bordered .nav-item a.nav-link
{
    color: #55595c; 
    background-color: #fff;
}
.nav.nav-pills.nav-pill-with-active-bordered .nav-item a.nav-link.active
{
    color: #6967ce; 
    border: 1px solid #6967ce;
}
.nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item ~ .nav-item a.nav-link
{
    border-top: 0; 
    border-left: 1px solid #d3d3d3;
}
.nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item:first-child a.nav-link
{
    border-radius: .25rem .25rem 0 0;
}
.nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item:last-child a.nav-link
{
    border-radius: 0 0 .25rem .25rem;
}
.nav.nav-pills.nav-pill-toolbar .nav-item
{
    margin-top: 0;
    margin-left: 0;
}
.nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link
{
    border: 1px solid #d3d3d3;
    border-radius: 0;
}
.nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link.active,
.nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link:active
{
    border-color: #6967ce;
}
.nav.nav-tabs .nav-item .nav-link
{
    line-height: normal;

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex; 

    padding: 1rem 1.5rem;
}
.nav.nav-tabs .nav-item .nav-link i
{
    font-size: 1.4rem;
}
.nav.nav-tabs .nav-item .nav-link:hover:not(.active)
{
    border-color: transparent;
}
.nav.nav-tabs .nav-item .nav-link.active
{
    border-radius: .25rem;
    -webkit-box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .3);
            box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .3);
}
.nav.nav-tabs .nav-item .nav-link i.float-right
{
    margin-left: .5rem;
}
.nav.nav-tabs .nav-item .nav-link.dropdown-toggle::after
{
    top: -1px;
}
.nav.nav-tabs .nav-item:hover
{
    color: #333;
}
.nav.nav-tabs.wrap-border
{
    border: 1px solid #ddd;
}
.nav.nav-tabs.no-hover-bg .nav-item:hover
{
    background-color: transparent;
}
.nav.nav-tabs .dropdown .dropdown-item.active
{
    color: #55595c; 
    background-color: transparent;
}
.nav.nav-tabs .dropdown .dropdown-item.active:focus
{
    color: #55595c; 
    background-color: transparent;
}
.nav .nav-item .nav-link
{
    display: block;
}
.nav .dropdown-divider
{
    margin: .5rem 0;
}

.nav-vertical
{
    overflow: hidden;
}
.nav-vertical .nav-left.nav-tabs
{
    display: table; 
    float: left;

    border-bottom: 0;
    border-radius: 0;
}
.nav-vertical .nav-left.nav-tabs li.nav-item
{
    float: none;

    margin: 0;
    margin-right: -1px;
}
.nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link
{
    min-width: 6.5rem;
}
.nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link.active
{
    border-right: 0;
    border-radius: .25rem;
}
.nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link:hover:not(.active)
{
    border-color: transparent;
}
.nav-vertical .nav-left.nav-tabs.nav-border-left li.nav-item a.nav-link
{
    color: #fa626b;
}
.nav-vertical .nav-left.nav-tabs.nav-border-left li.nav-item a.nav-link.active
{
    color: #555; 
    border-left: 3px solid #fa626b;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.nav-vertical .nav-left ~ .tab-content
{
    margin-left: 45px;
}
.nav-vertical .nav-left ~ .tab-content .tab-pane
{
    display: none;
    overflow-y: auto; 

    padding: 0 0 .6rem 1rem;

    background-color: #fff;
}
.nav-vertical .nav-left ~ .tab-content .tab-pane.active
{
    display: block;
}
.nav-vertical .nav-right.nav-tabs
{
    display: table; 
    float: right;

    border-bottom: 0;
    border-radius: 0;
}
.nav-vertical .nav-right.nav-tabs li.nav-item
{
    float: none;

    margin: 0;
    margin-left: -1px;
}
.nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link
{
    min-width: 6.5rem;
}
.nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link.active
{
    border-left: 0;
    border-radius: .25rem;
}
.nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link:hover:not(.active)
{
    border-color: transparent;
}
.nav-vertical .nav-right.nav-tabs.nav-border-right li.nav-item a.nav-link
{
    color: #fa626b;
}
.nav-vertical .nav-right.nav-tabs.nav-border-right li.nav-item a.nav-link.active
{
    color: #555; 
    border-right: 3px solid #fa626b;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.nav-vertical .nav-right ~ .tab-content .tab-pane
{
    display: none;
    overflow-y: auto; 

    padding: 0;

    background-color: #fff;
}
.nav-vertical .nav-right ~ .tab-content .tab-pane.active
{
    display: block;
}

.nav.nav-tabs.nav-only-icon .nav-item .nav-link
{
    font-size: 1.25rem;
}
.nav.nav-tabs.nav-only-icon .nav-item .nav-link i
{
    margin-left: .5rem;
}

.nav.nav-tabs .nav-item.show .nav-link,
.nav.nav-tabs .nav-item.show .nav-link:focus
{
    color: #6967ce; 
    background-color: transparent;
}

.nav-link
{
    color: #6967ce;
}
.nav-link.active
{
    color: #fa626b;
}
.nav-link.disabled
{
    color: #626e82;
}

.alert
{
    position: relative;
}
.alert .alert-link:hover
{
    text-decoration: underline;
}
.alert.alert-icon-left
{
    border-left-width: 4rem;
}
.alert.alert-icon-right
{
    border-right-width: 4rem;
}
.alert.alert-icon-left[class*=bg-]
{
    border-left-color: rgba(0, 0, 0, .15) !important;
}
.alert.alert-icon-right[class*=bg-]
{
    border-right-color: rgba(0, 0, 0, .15) !important;
}
.alert[class*=alert-icon-] .alert-icon
{
    font-family: LineAwesome;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    top: 50%;
    left: -4rem;

    width: 4rem;
    margin-top: -8px;

    content: '\f2b2';
    text-align: center;

    color: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.alert .close
{
    font-size: 2rem;
    font-weight: 300;
    line-height: 1rem;

    opacity: 1; 
    text-shadow: none;
}
.alert[class*=bg-]
{
    color: #fff;
}
.alert[class*=bg-] .alert-link
{
    color: #fff;
}
.alert[class*=bg-] .close
{
    opacity: 1;
    color: #fff;
    text-shadow: none;
}
.alert.alert-icon-right .alert-icon
{
    right: -4rem; 
    left: auto;
}
.alert[class*=alert-arrow-]:before
{
    position: absolute;
    top: 50%;
    left: 0;

    display: inline-block;

    margin-top: -8px; 

    content: '';

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid;
    border-left-color: inherit;
}
.alert.alert-arrow-right:before
{
    right: 0;
    left: auto;

    border-right: 8px solid;
    border-right-color: inherit; 
    border-left: 0;
}

.media-list .media
{
    margin-top: 0; 
    padding: 1.5rem;
}
.media-list .media .media-left
{
    float: left; 

    padding-right: 1rem;
}
.media-list .media .media-right
{
    float: right; 

    padding-left: 1rem;
}

.media-list a.media
{
    color: #6b6f80 !important;
}

.media [class*='avatar-']
{
    position: relative;

    margin-right: 0; 
    padding-right: 0 !important;
}
.media [class*='avatar-'] i
{
    position: absolute;
    right: -4px;
    bottom: -1px;

    width: 10px;
    height: 10px;

    border: 2px solid white;
    border-radius: 100%;
}

.media-notation a
{
    margin-right: .5rem;
}

ul.media-list li
{
    line-height: 1.5rem;
}

/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Creative Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
  Version: 1.0
  Author: ThemeSelection
  Author URL: https://themeforest.net/user/themeselect
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/
.progress
{
    margin-bottom: 3rem;
}
.progress:last-child
{
    margin-bottom: 1rem;
}
.progress.progress-xl
{
    height: 2rem !important;
}
.progress.progress-lg
{
    height: 1.5rem !important;
}
.progress.progress-md
{
    height: 1rem !important;
}
.progress.progress-sm
{
    height: .5rem !important;
}
.progress.progress-xs
{
    height: .25rem !important;
}
.progress.box-shadow
{
    -webkit-box-shadow: 0 10px 15px 0 rgba(107, 111, 128, .3);
            box-shadow: 0 10px 15px 0 rgba(107, 111, 128, .3);
}

/* ===============================================================================================
	File Name: list-group.scss
	Description: Contain list item, list group related extended SCSS.
	----------------------------------------------------------------------------------------------
	Item Name: Creative Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
	Version: 1.0
	Author: ThemeSelection
	Author URL: https://themeforest.net/user/themeselect
================================================================================================*/
ul.list-inline li
{
    display: inline-block;
}

ul.list-inline.list-inline-pipe > li + li:before
{
    content: ' | ';

    color: #626e82;
}

.list-group .list-group-item.active
{
    color: #fff !important;
}

.list-group .list-group-item-action:active
{
    color: #fff;
    background-color: #6967ce;
}
.list-group .list-group-item-action:active a
{
    color: #fff;
}

.users-list li + li
{
    margin-left: -14px;
}

.users-list li img
{
    border: 2px solid #fff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(107, 111, 128, .3);
            box-shadow: 0 2px 10px 0 rgba(107, 111, 128, .3);
}

.users-list li .badge
{
    margin-bottom: 6px; 

    color: #6b6f80;
    background: rgba(255, 255, 255, .8);
}

.modal-content
{
    -webkit-box-shadow: 0 10px 50px 0 rgba(70, 72, 85, .8) !important;
            box-shadow: 0 10px 50px 0 rgba(70, 72, 85, .8) !important;
}

.modal-sm
{
    max-width: 400px;
}

.modal-xs
{
    max-width: 300px;
}

.modal-xl
{
    max-width: 94%;
    margin-right: 3%; 
    margin-left: 3%;
}

.icon-align
{
    margin-top: .6rem;
    margin-left: .5rem;
}

.bd-example-modal .modal
{
    position: relative;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    display: block;
    overflow: visible;
}

.popover
{
    -webkit-box-shadow: 0 10px 50px 0 rgba(0, 0, 0, .2);
            box-shadow: 0 10px 50px 0 rgba(0, 0, 0, .2);
}
.popover.popover-top:after,
.popover.popover-top:before,
.popover.bs-tether-element-attached-top:after,
.popover.bs-tether-element-attached-top:before
{
    margin-left: -10px;
}
.popover.popover-right:after,
.popover.popover-right:before,
.popover.bs-tether-element-attached-right:after,
.popover.bs-tether-element-attached-right:before
{
    margin-top: -10px;
}
.popover.popover-bottom:after,
.popover.popover-bottom:before,
.popover.bs-tether-element-attached-bottom:after,
.popover.bs-tether-element-attached-bottom:before
{
    margin-left: -10px;
}
.popover.popover-left:after,
.popover.popover-left:before,
.popover.bs-tether-element-attached-left:after,
.popover.bs-tether-element-attached-left:before
{
    margin-top: -10px;
}

.icon-bg-circle
{
    padding: .5rem;

    color: #fff;
    border-radius: 50%;
}

.icon-left
{
    margin-right: .5rem;
}

.icon-right
{
    margin-right: .5rem;
}

.icon-spin
{
    display: inline-block;

    -webkit-animation: spin 1s infinite linear;
       -moz-animation: spin 1s infinite linear;
         -o-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}

.icon-shadow
{
    line-height: 65px;

    position: relative; 

    width: 65px;
    width: 50px;
    max-width: 65px;
    max-width: 50px;
    height: 65px;
    height: 50px;

    text-align: center;
    vertical-align: middle;

    border-radius: 12%;
    background-color: #fff;
}
.icon-shadow.icon-shadow-primary
{
    -webkit-box-shadow: 0 2px 15px #b5b4e7; 
            box-shadow: 0 2px 15px #b5b4e7;
}
.icon-shadow.icon-shadow-success
{
    -webkit-box-shadow: 0 2px 15px #84e179; 
            box-shadow: 0 2px 15px #84e179;
}
.icon-shadow.icon-shadow-warning
{
    -webkit-box-shadow: 0 2px 15px #fed566; 
            box-shadow: 0 2px 15px #fed566;
}
.icon-shadow.icon-shadow-info
{
    -webkit-box-shadow: 0 2px 15px #79d0e5; 
            box-shadow: 0 2px 15px #79d0e5;
}
.icon-shadow.icon-shadow-danger
{
    -webkit-box-shadow: 0 2px 15px #fc939a; 
            box-shadow: 0 2px 15px #fc939a;
}

.icon-opacity
{
    opacity: .5;
}

.blockOverlay
{
    z-index: 1050 !important;
}

.blockElement,
.blockPage
{
    z-index: 1051 !important;
}

.embed-responsive:before
{
    padding-top: 57%;
}

@-moz-keyframes spin
{
    from
    {
        -moz-transform: rotate(0deg);
    }
    to
    {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin
{
    from
    {
        -webkit-transform: rotate(0deg);
    }
    to
    {
        -webkit-transform: rotate(360deg);
    }
}

@-o-keyframes spin
{
    from
    {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    to
    {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes spin
{
    from
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to
    {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.hidden
{
    display: none;
    visibility: hidden;
}

a:hover
{
    cursor: pointer;
}

.round
{
    border-radius: 1.5rem;
}

.square
{
    border-radius: 0;
}

.mega-dropdown-menu p.accordion-text
{
    line-height: 1.45;
}

.mega-dropdown-menu a[aria-expanded='true']
{
    padding-bottom: 1rem;
}

.line-on-side
{
    line-height: .1em;

    margin: 10px 0 20px; 

    border-bottom: 1px solid #dadada;
}

.line-on-side span
{
    padding: 0 10px; 

    background: #fff;
}

.icon-line-height
{
    line-height: 1.5rem !important;
}

.table-middle td
{
    vertical-align: middle;
}

.spinner
{
    display: inline-block;

    -webkit-animation: spin 2s linear infinite;
       -moz-animation: spin 2s linear infinite;
         -o-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

@keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
