/*=========================================================================================
    File Name: vertical-menu.scss
    Description: A calssic vertical style menu with expand and collops support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Creative Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.0
    Author: ThemeSelection
    Author URL: https://themeforest.net/user/themeselect
==========================================================================================*/
@media (min-width: 576px)
{
    body.vertical-layout.vertical-menu.menu-flipped .main-menu
    {
        right: 0;
    }
}

body.vertical-layout.vertical-menu .navbar .navbar-brand
{
    white-space: nowrap;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar-header
{
    z-index: 999;

    float: left; 

    width: 260px;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar.navbar-brand-center .navbar-header
{
    z-index: 999; 

    width: auto;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-container
{
    margin-left: 260px;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-brand .brand-text
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}

body.vertical-layout.vertical-menu.menu-expanded .main-menu
{
    width: 260px;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation .navigation-header .ft-minus
{
    display: none;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > i:before
{
    font-size: 1.5rem;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span
{
    -webkit-transition: -webkit-transform .3s ease;
       -moz-transition:         transform .3s ease, -moz-transform .3s ease;
         -o-transition:      -o-transform .3s ease;
            transition: -webkit-transform .3s ease;
            transition:         transform .3s ease;
            transition:         transform .3s ease, -webkit-transform .3s ease, -moz-transform .3s ease, -o-transform .3s ease;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span.badge
{
    position: absolute;
    right: 20px;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: .85rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu .main-menu-footer
{
    width: 260px;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation > li > a > i
{
    line-height: 40px;

    display: inline-block;

    width: 40px;
    height: 40px;
    margin-right: 20px;

    text-align: center;
    vertical-align: middle;

    border-radius: 12%;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .12);
            box-shadow: 0 0 10px rgba(0, 0, 0, .12);
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .hover > a > i,
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation:hover > a > i,
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .open > a > i
{
    -webkit-box-shadow: 0 0 20px rgba(253, 99, 107, .3);
            box-shadow: 0 0 20px rgba(253, 99, 107, .3);
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation > li > a > i
{
    line-height: 40px;

    display: inline-block;

    width: 40px;
    height: 40px;
    margin-right: 20px;

    text-align: center;
    vertical-align: middle;

    border-radius: 12%;
    background: #2c303b;
    -webkit-box-shadow: 0 0 10px black;
            box-shadow: 0 0 10px black;
}
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation .hover > a > i,
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation:hover > a > i,
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation .open > a > i
{
    -webkit-box-shadow: 0 0 20px rgba(253, 99, 107, .3);
            box-shadow: 0 0 20px rgba(253, 99, 107, .3);
}

body.vertical-layout.vertical-menu.menu-expanded .content,
body.vertical-layout.vertical-menu.menu-expanded .footer
{
    margin-left: 260px;
}

body.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .content,
body.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .footer
{
    margin-left: 0;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar-header
{
    z-index: 999;

    float: left; 

    width: 60px;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar.navbar-brand-center .navbar-header
{
    z-index: 999; 

    width: auto;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand
{
    padding: 17px 0;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-container
{
    margin-left: 60px;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand .brand-text
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
}

body.vertical-layout.vertical-menu.menu-collapsed .main-menu
{
    width: 60px;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all; 
    -webkit-transform: translateZ(-160px) translateX(-160px);
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translateZ(-160px) translateX(-160px);
       -moz-transform: translate3d(0, 0, 0);
            transform: translateZ(-160px) translateX(-160px);
            transform: translate3d(0, 0, 0);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer
{
    width: 60px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body .media-heading,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body .text-muted,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-right
{
    display: none;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-body
{
    opacity: 0;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .user-content
{
    padding: 20px 10px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > span.menu-title,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content a.menu-title
{
    font-weight: 600;

    left: 60px;

    width: 260px;
    padding: 18px 30px; 

    text-align: left;
    text-transform: uppercase;

    color: #fff;
    border-color: #fa626b;
    background-color: #fa626b;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content
{
    left: 60px;

    width: 260px;

    -webkit-transition: visibility .25s, opacity .25s;
       -moz-transition: visibility .25s, opacity .25s;
         -o-transition: visibility .25s, opacity .25s;
            transition: visibility .25s, opacity .25s;

    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-left: 1px solid rgba(0, 0, 0, .02); 
    -webkit-box-shadow: 25px 5px 75px 2px rgba(64, 70, 74, .2);
            box-shadow: 25px 5px 75px 2px rgba(64, 70, 74, .2);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li
{
    position: relative; 

    white-space: nowrap;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li a
{
    display: block;

    padding: 8px 30px 8px 30px;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.active a
{
    color: #fa626b;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: .85rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li:hover > a,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.hover > a
{
    -webkit-transform: translateX(4px);
       -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
         -o-transform: translateX(4px);
            transform: translateX(4px);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li:hover > a > a,
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.hover > a > a
{
    -webkit-transform: translateX(-4px);
       -moz-transform: translateX(-4px);
        -ms-transform: translateX(-4px);
         -o-transform: translateX(-4px);
            transform: translateX(-4px);
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu.menu-icon-right .navigation > li > a
{
    padding: 12px 30px 12px 18px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation
{
    overflow: visible;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header
{
    padding: 22px 20px 8px 20px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus
{
    font-size: 1.3rem; 

    display: block;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header span
{
    display: none;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a
{
    padding: 10px 0 10px 18px;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span
{
    font-weight: 600;

    position: absolute;
    top: 0;
    right: -260px;

    visibility: hidden;

    width: 260px;
    padding: 14px 20px; 

    text-align: left;

    opacity: 0;
    color: #fff;
    border-color: #6967ce;
    background-color: #6967ce;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > i
{
    font-size: 1.5rem;
    line-height: 1;

    visibility: visible;

    margin-right: 0;

    opacity: 1;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > ul
{
    display: none;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span
{
    display: none;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .mTSWrapper
{
    overflow: visible;
}
body.vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub > a:not(.mm-next):after
{
    top: 10px;
}

body.vertical-layout.vertical-menu.menu-collapsed .content,
body.vertical-layout.vertical-menu.menu-collapsed .footer
{
    margin-left: 60px;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .content,
body.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .footer
{
    margin-left: 0;
}

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .content
{
    margin: 0;
    margin-right: 60px;
}

@media (min-width: 576px)
{
    body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu
    {
        right: 0;
    }
    body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu span.menu-title
    {
        left: -260px !important;
    }
    body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu ul.menu-content
    {
        right: 60px;
        left: inherit;
    }
}

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar-header
{
    float: right;
}

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar .navbar-container
{
    margin: 0;
    margin-right: 60px;
}

body.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .footer
{
    margin: 0;
    margin-right: 60px;
}

[data-textdirection='rtl'] body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a
{
    padding: 14px 16px !important;
}

[data-textdirection='rtl'] body.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus
{
    padding: 12px 0;
}

@media (min-width: 992px)
{
    body.vertical-layout.vertical-menu.menu-flipped .content
    {
        margin: 0;
        margin-right: 260px;
    }
    body.vertical-layout.vertical-menu.menu-flipped .navbar-header
    {
        float: right;
    }
    body.vertical-layout.vertical-menu.menu-flipped .navbar .navbar-container
    {
        margin: 0;
        margin-right: 260px;
    }
    body.vertical-layout.vertical-menu.menu-flipped .footer
    {
        margin: 0;
        margin-right: 260px;
    }
}

@media (max-width: 991.98px)
{
    body.vertical-layout.vertical-menu .main-menu
    {
        width: 60px;
    }
    body.vertical-layout.vertical-menu .main-menu .main-menu-footer
    {
        width: 260px;
    }
    body.vertical-layout.vertical-menu .navbar-header
    {
        width: 60px;
    }
    body.vertical-layout.vertical-menu .content,
    body.vertical-layout.vertical-menu .footer
    {
        margin-left: 60px;
    }
}

@media (max-width: 767.98px)
{
    body.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-container
    {
        margin-left: 0 !important;
    }
    body.vertical-layout.vertical-menu.menu-expanded .content,
    body.vertical-layout.vertical-menu.menu-expanded .footer
    {
        margin-left: 0 !important;
    }
    html.loading .main-menu,
    html.loading .vertical-overlay-menu.menu-hide .main-menu,
    .vertical-overlay-menu.menu-hide html.loading .main-menu
    {
        -webkit-transform: translate3d(0px, 0, 0);
           -moz-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0); 

        opacity: 0;
    }
}

@-webkit-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-moz-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-o-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@-moz-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@-o-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

/*=========================================================================================
	File Name: vertical-overlay-menu.scss
	Description: A overlay style vertical menu with show and hide support. It support 
	light & dark version, filpped layout, right side icons, native scroll and borders menu 
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Creative Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
	Version: 1.0
	Author: ThemeSelection
	Author URL: https://themeforest.net/user/themeselect
==========================================================================================*/
.vertical-overlay-menu .content
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header
{
    z-index: 999; 

    width: 260px;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-container
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header
{
    z-index: 999; 

    width: auto;
}

.vertical-overlay-menu .main-menu,
.vertical-overlay-menu.menu-hide .main-menu
{
    left: -260px; 

    width: 260px;

    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

    opacity: 0;
}
.vertical-overlay-menu .main-menu .navigation .navigation-header .ft-minus
{
    display: none;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i
{
    font-size: 1.5rem;

    float: left; 

    margin-right: 12px;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i:before
{
    -webkit-transition: 200ms ease all;
       -moz-transition: 200ms ease all;
         -o-transition: 200ms ease all;
            transition: 200ms ease all;
}
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: .85rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
.vertical-overlay-menu .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    bottom: 55px;
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    width: 260px;
}

.vertical-overlay-menu.menu-open .main-menu
{
    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s; 
    -webkit-transform: translate3d(260px, 0, 0);
       -moz-transform: translate3d(260px, 0, 0);
            transform: translate3d(260px, 0, 0);

    opacity: 1;
}
.vertical-overlay-menu.menu-open .main-menu.menu-light .navigation > li > a > i
{
    line-height: 40px;

    display: inline-block;

    width: 40px;
    height: 40px;
    margin-right: 20px;

    text-align: center;
    vertical-align: middle;

    border-radius: 12%;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .12);
            box-shadow: 0 0 10px rgba(0, 0, 0, .12);
}
.vertical-overlay-menu.menu-open .main-menu.menu-light .navigation .hover > a > i,
.vertical-overlay-menu.menu-open .main-menu.menu-light .navigation:hover > a > i,
.vertical-overlay-menu.menu-open .main-menu.menu-light .navigation .open > a > i
{
    -webkit-box-shadow: 0 0 20px rgba(253, 99, 107, .3);
            box-shadow: 0 0 20px rgba(253, 99, 107, .3);
}
.vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation > li > a > i
{
    line-height: 40px;

    display: inline-block;

    width: 40px;
    height: 40px;
    margin-right: 20px;

    text-align: center;
    vertical-align: middle;

    border-radius: 12%;
    background: #2c303b;
    -webkit-box-shadow: 0 0 10px black;
            box-shadow: 0 0 10px black;
}
.vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation .hover > a > i,
.vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation:hover > a > i,
.vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation .open > a > i
{
    -webkit-box-shadow: 0 0 20px rgba(253, 99, 107, .3);
            box-shadow: 0 0 20px rgba(253, 99, 107, .3);
}

.vertical-overlay-menu.menu-flipped .main-menu
{
    right: -260px;
    left: inherit;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-container
{
    margin: 0;
    margin-right: 260px;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-header
{
    float: right;
}

.vertical-overlay-menu.menu-flipped.menu-open .main-menu
{
    -webkit-transform: translate3d(-260px, 0, 0);
       -moz-transform: translate3d(-260px, 0, 0);
            transform: translate3d(-260px, 0, 0);
}

@media (max-width: 991.98px)
{
    .vertical-overlay-menu .main-menu .main-menu-footer
    {
        bottom: 0;
    }
}
