.bg-gradient-x-white .card-header,
.bg-gradient-x-white .card-footer,
.bg-gradient-y-white .card-header,
.bg-gradient-y-white .card-footer,
.bg-gradient-directional-white .card-header,
.bg-gradient-directional-white .card-footer,
.bg-gradient-radial-white .card-header,
.bg-gradient-radial-white .card-footer,
.bg-gradient-striped-white .card-header,
.bg-gradient-striped-white .card-footer,
.bg-gradient-x2-white .card-header,
.bg-gradient-x2-white .card-footer,
.bg-gradient-y2-white .card-header,
.bg-gradient-y2-white .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-black .card-header,
.bg-gradient-x-black .card-footer,
.bg-gradient-y-black .card-header,
.bg-gradient-y-black .card-footer,
.bg-gradient-directional-black .card-header,
.bg-gradient-directional-black .card-footer,
.bg-gradient-radial-black .card-header,
.bg-gradient-radial-black .card-footer,
.bg-gradient-striped-black .card-header,
.bg-gradient-striped-black .card-footer,
.bg-gradient-x2-black .card-header,
.bg-gradient-x2-black .card-footer,
.bg-gradient-y2-black .card-header,
.bg-gradient-y2-black .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-chartbg .card-header,
.bg-gradient-x-chartbg .card-footer,
.bg-gradient-y-chartbg .card-header,
.bg-gradient-y-chartbg .card-footer,
.bg-gradient-directional-chartbg .card-header,
.bg-gradient-directional-chartbg .card-footer,
.bg-gradient-radial-chartbg .card-header,
.bg-gradient-radial-chartbg .card-footer,
.bg-gradient-striped-chartbg .card-header,
.bg-gradient-striped-chartbg .card-footer,
.bg-gradient-x2-chartbg .card-header,
.bg-gradient-x2-chartbg .card-footer,
.bg-gradient-y2-chartbg .card-header,
.bg-gradient-y2-chartbg .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#5654c2), to(#9695dd));
    background-image: -webkit-linear-gradient(left, #5654c2 0%, #9695dd 100%);
    background-image:    -moz-linear-gradient(left, #5654c2 0%, #9695dd 100%);
    background-image:      -o-linear-gradient(left, #5654c2 0%, #9695dd 100%);
    background-image:         linear-gradient(to right, #5654c2 0%, #9695dd 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5654c2), to(#9695dd));
    background-image: -webkit-linear-gradient(top, #5654c2 0%, #9695dd 100%);
    background-image:    -moz-linear-gradient(top, #5654c2 0%, #9695dd 100%);
    background-image:      -o-linear-gradient(top, #5654c2 0%, #9695dd 100%);
    background-image:         linear-gradient(to bottom, #5654c2 0%, #9695dd 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-primary
{
    background-image: -webkit-linear-gradient(45deg, #5654c2, #9695dd);
    background-image:    -moz-linear-gradient(45deg, #5654c2, #9695dd);
    background-image:      -o-linear-gradient(45deg, #5654c2, #9695dd);
    background-image:         linear-gradient(45deg, #5654c2, #9695dd);
    background-repeat: repeat-x;
}

.bg-gradient-x2-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9695dd), color-stop(50%, #6967ce), to(#b4b3e7));
    background-image: -webkit-linear-gradient(left, #9695dd, #6967ce 50%, #b4b3e7);
    background-image:    -moz-linear-gradient(left, #9695dd, #6967ce 50%, #b4b3e7);
    background-image:      -o-linear-gradient(left, #9695dd, #6967ce 50%, #b4b3e7);
    background-image:         linear-gradient(to right, #9695dd, #6967ce 50%, #b4b3e7);
    background-repeat: no-repeat;
}

.bg-gradient-y2-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9695dd), color-stop(50%, #6967ce), to(#b4b3e7));
    background-image: -webkit-linear-gradient(#9695dd, #6967ce 50%, #b4b3e7);
    background-image:    -moz-linear-gradient(#9695dd, #6967ce 50%, #b4b3e7);
    background-image:      -o-linear-gradient(#9695dd, #6967ce 50%, #b4b3e7);
    background-image:         linear-gradient(#9695dd, #6967ce 50%, #b4b3e7);
    background-repeat: no-repeat;
}

.bg-gradient-radial-primary
{
    background-image: -webkit-radial-gradient(circle, #5654c2, #9695dd);
    background-image:    -moz-radial-gradient(circle, #5654c2, #9695dd);
    background-image:      -o-radial-gradient(circle, #5654c2, #9695dd);
    background-image:         radial-gradient(circle, #5654c2, #9695dd);
    background-repeat: no-repeat;
}

.bg-gradient-striped-primary
{
    background-image: -webkit-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
}

.bg-gradient-x-primary .card-header,
.bg-gradient-x-primary .card-footer,
.bg-gradient-y-primary .card-header,
.bg-gradient-y-primary .card-footer,
.bg-gradient-directional-primary .card-header,
.bg-gradient-directional-primary .card-footer,
.bg-gradient-radial-primary .card-header,
.bg-gradient-radial-primary .card-footer,
.bg-gradient-striped-primary .card-header,
.bg-gradient-striped-primary .card-footer,
.bg-gradient-x2-primary .card-header,
.bg-gradient-x2-primary .card-footer,
.bg-gradient-y2-primary .card-header,
.bg-gradient-y2-primary .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#63d457), to(#9fe797));
    background-image: -webkit-linear-gradient(left, #63d457 0%, #9fe797 100%);
    background-image:    -moz-linear-gradient(left, #63d457 0%, #9fe797 100%);
    background-image:      -o-linear-gradient(left, #63d457 0%, #9fe797 100%);
    background-image:         linear-gradient(to right, #63d457 0%, #9fe797 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#63d457), to(#9fe797));
    background-image: -webkit-linear-gradient(top, #63d457 0%, #9fe797 100%);
    background-image:    -moz-linear-gradient(top, #63d457 0%, #9fe797 100%);
    background-image:      -o-linear-gradient(top, #63d457 0%, #9fe797 100%);
    background-image:         linear-gradient(to bottom, #63d457 0%, #9fe797 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-success
{
    background-image: -webkit-linear-gradient(45deg, #63d457, #9fe797);
    background-image:    -moz-linear-gradient(45deg, #63d457, #9fe797);
    background-image:      -o-linear-gradient(45deg, #63d457, #9fe797);
    background-image:         linear-gradient(45deg, #63d457, #9fe797);
    background-repeat: repeat-x;
}

.bg-gradient-x2-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9fe797), color-stop(50%, #5ed84f), to(#bbeeb5));
    background-image: -webkit-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
    background-image:    -moz-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
    background-image:      -o-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
    background-image:         linear-gradient(to right, #9fe797, #5ed84f 50%, #bbeeb5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9fe797), color-stop(50%, #5ed84f), to(#bbeeb5));
    background-image: -webkit-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
    background-image:    -moz-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
    background-image:      -o-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
    background-image:         linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-success
{
    background-image: -webkit-radial-gradient(circle, #63d457, #9fe797);
    background-image:    -moz-radial-gradient(circle, #63d457, #9fe797);
    background-image:      -o-radial-gradient(circle, #63d457, #9fe797);
    background-image:         radial-gradient(circle, #63d457, #9fe797);
    background-repeat: no-repeat;
}

.bg-gradient-striped-success
{
    background-image: -webkit-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
}

.bg-gradient-x-success .card-header,
.bg-gradient-x-success .card-footer,
.bg-gradient-y-success .card-header,
.bg-gradient-y-success .card-footer,
.bg-gradient-directional-success .card-header,
.bg-gradient-directional-success .card-footer,
.bg-gradient-radial-success .card-header,
.bg-gradient-radial-success .card-footer,
.bg-gradient-striped-success .card-header,
.bg-gradient-striped-success .card-footer,
.bg-gradient-x2-success .card-header,
.bg-gradient-x2-success .card-footer,
.bg-gradient-y2-success .card-header,
.bg-gradient-y2-success .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#1e9fc4), to(#69c7de));
    background-image: -webkit-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
    background-image:    -moz-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
    background-image:      -o-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
    background-image:         linear-gradient(to right, #1e9fc4 0%, #69c7de 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1e9fc4), to(#69c7de));
    background-image: -webkit-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
    background-image:    -moz-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
    background-image:      -o-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
    background-image:         linear-gradient(to bottom, #1e9fc4 0%, #69c7de 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-info
{
    background-image: -webkit-linear-gradient(45deg, #1e9fc4, #69c7de);
    background-image:    -moz-linear-gradient(45deg, #1e9fc4, #69c7de);
    background-image:      -o-linear-gradient(45deg, #1e9fc4, #69c7de);
    background-image:         linear-gradient(45deg, #1e9fc4, #69c7de);
    background-repeat: repeat-x;
}

.bg-gradient-x2-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#69c7de), color-stop(50%, #28afd0), to(#94d7e8));
    background-image: -webkit-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
    background-image:    -moz-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
    background-image:      -o-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
    background-image:         linear-gradient(to right, #69c7de, #28afd0 50%, #94d7e8);
    background-repeat: no-repeat;
}

.bg-gradient-y2-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#69c7de), color-stop(50%, #28afd0), to(#94d7e8));
    background-image: -webkit-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
    background-image:    -moz-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
    background-image:      -o-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
    background-image:         linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
    background-repeat: no-repeat;
}

.bg-gradient-radial-info
{
    background-image: -webkit-radial-gradient(circle, #1e9fc4, #69c7de);
    background-image:    -moz-radial-gradient(circle, #1e9fc4, #69c7de);
    background-image:      -o-radial-gradient(circle, #1e9fc4, #69c7de);
    background-image:         radial-gradient(circle, #1e9fc4, #69c7de);
    background-repeat: no-repeat;
}

.bg-gradient-striped-info
{
    background-image: -webkit-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
}

.bg-gradient-x-info .card-header,
.bg-gradient-x-info .card-footer,
.bg-gradient-y-info .card-header,
.bg-gradient-y-info .card-footer,
.bg-gradient-directional-info .card-header,
.bg-gradient-directional-info .card-footer,
.bg-gradient-radial-info .card-header,
.bg-gradient-radial-info .card-footer,
.bg-gradient-striped-info .card-header,
.bg-gradient-striped-info .card-footer,
.bg-gradient-x2-info .card-header,
.bg-gradient-x2-info .card-footer,
.bg-gradient-y2-info .card-header,
.bg-gradient-y2-info .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fcaa01), to(#fece4d));
    background-image: -webkit-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
    background-image:    -moz-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
    background-image:      -o-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
    background-image:         linear-gradient(to right, #fcaa01 0%, #fece4d 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fcaa01), to(#fece4d));
    background-image: -webkit-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
    background-image:    -moz-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
    background-image:      -o-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
    background-image:         linear-gradient(to bottom, #fcaa01 0%, #fece4d 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-warning
{
    background-image: -webkit-linear-gradient(45deg, #fcaa01, #fece4d);
    background-image:    -moz-linear-gradient(45deg, #fcaa01, #fece4d);
    background-image:      -o-linear-gradient(45deg, #fcaa01, #fece4d);
    background-image:         linear-gradient(45deg, #fcaa01, #fece4d);
    background-repeat: repeat-x;
}

.bg-gradient-x2-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fece4d), color-stop(50%, #fdb901), to(#fedc80));
    background-image: -webkit-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
    background-image:    -moz-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
    background-image:      -o-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
    background-image:         linear-gradient(to right, #fece4d, #fdb901 50%, #fedc80);
    background-repeat: no-repeat;
}

.bg-gradient-y2-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fece4d), color-stop(50%, #fdb901), to(#fedc80));
    background-image: -webkit-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
    background-image:    -moz-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
    background-image:      -o-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
    background-image:         linear-gradient(#fece4d, #fdb901 50%, #fedc80);
    background-repeat: no-repeat;
}

.bg-gradient-radial-warning
{
    background-image: -webkit-radial-gradient(circle, #fcaa01, #fece4d);
    background-image:    -moz-radial-gradient(circle, #fcaa01, #fece4d);
    background-image:      -o-radial-gradient(circle, #fcaa01, #fece4d);
    background-image:         radial-gradient(circle, #fcaa01, #fece4d);
    background-repeat: no-repeat;
}

.bg-gradient-striped-warning
{
    background-image: -webkit-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
}

.bg-gradient-x-warning .card-header,
.bg-gradient-x-warning .card-footer,
.bg-gradient-y-warning .card-header,
.bg-gradient-y-warning .card-footer,
.bg-gradient-directional-warning .card-header,
.bg-gradient-directional-warning .card-footer,
.bg-gradient-radial-warning .card-header,
.bg-gradient-radial-warning .card-footer,
.bg-gradient-striped-warning .card-header,
.bg-gradient-striped-warning .card-footer,
.bg-gradient-x2-warning .card-header,
.bg-gradient-x2-warning .card-footer,
.bg-gradient-y2-warning .card-header,
.bg-gradient-y2-warning .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#f95058), to(#fc9197));
    background-image: -webkit-linear-gradient(left, #f95058 0%, #fc9197 100%);
    background-image:    -moz-linear-gradient(left, #f95058 0%, #fc9197 100%);
    background-image:      -o-linear-gradient(left, #f95058 0%, #fc9197 100%);
    background-image:         linear-gradient(to right, #f95058 0%, #fc9197 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f95058), to(#fc9197));
    background-image: -webkit-linear-gradient(top, #f95058 0%, #fc9197 100%);
    background-image:    -moz-linear-gradient(top, #f95058 0%, #fc9197 100%);
    background-image:      -o-linear-gradient(top, #f95058 0%, #fc9197 100%);
    background-image:         linear-gradient(to bottom, #f95058 0%, #fc9197 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-danger
{
    background-image: -webkit-linear-gradient(45deg, #f95058, #fc9197);
    background-image:    -moz-linear-gradient(45deg, #f95058, #fc9197);
    background-image:      -o-linear-gradient(45deg, #f95058, #fc9197);
    background-image:         linear-gradient(45deg, #f95058, #fc9197);
    background-repeat: repeat-x;
}

.bg-gradient-x2-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fc9197), color-stop(50%, #fa626b), to(#fdb1b5));
    background-image: -webkit-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
    background-image:    -moz-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
    background-image:      -o-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
    background-image:         linear-gradient(to right, #fc9197, #fa626b 50%, #fdb1b5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fc9197), color-stop(50%, #fa626b), to(#fdb1b5));
    background-image: -webkit-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
    background-image:    -moz-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
    background-image:      -o-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
    background-image:         linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-danger
{
    background-image: -webkit-radial-gradient(circle, #f95058, #fc9197);
    background-image:    -moz-radial-gradient(circle, #f95058, #fc9197);
    background-image:      -o-radial-gradient(circle, #f95058, #fc9197);
    background-image:         radial-gradient(circle, #f95058, #fc9197);
    background-repeat: no-repeat;
}

.bg-gradient-striped-danger
{
    background-image: -webkit-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
}

.bg-gradient-x-danger .card-header,
.bg-gradient-x-danger .card-footer,
.bg-gradient-y-danger .card-header,
.bg-gradient-y-danger .card-footer,
.bg-gradient-directional-danger .card-header,
.bg-gradient-directional-danger .card-footer,
.bg-gradient-radial-danger .card-header,
.bg-gradient-radial-danger .card-footer,
.bg-gradient-striped-danger .card-header,
.bg-gradient-striped-danger .card-footer,
.bg-gradient-x2-danger .card-header,
.bg-gradient-x2-danger .card-footer,
.bg-gradient-y2-danger .card-header,
.bg-gradient-y2-danger .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to right, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to bottom, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-red
{
    background-image: -webkit-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:    -moz-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:      -o-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:         linear-gradient(45deg, #d32f2f, #e57373);
    background-repeat: repeat-x;
}

.bg-gradient-x2-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(to right, #e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-y2-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-radial-red
{
    background-image: -webkit-radial-gradient(circle, #d32f2f, #e57373);
    background-image:    -moz-radial-gradient(circle, #d32f2f, #e57373);
    background-image:      -o-radial-gradient(circle, #d32f2f, #e57373);
    background-image:         radial-gradient(circle, #d32f2f, #e57373);
    background-repeat: no-repeat;
}

.bg-gradient-striped-red
{
    background-image: -webkit-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
}

.bg-gradient-x-red .card-header,
.bg-gradient-x-red .card-footer,
.bg-gradient-y-red .card-header,
.bg-gradient-y-red .card-footer,
.bg-gradient-directional-red .card-header,
.bg-gradient-directional-red .card-footer,
.bg-gradient-radial-red .card-header,
.bg-gradient-radial-red .card-footer,
.bg-gradient-striped-red .card-header,
.bg-gradient-striped-red .card-footer,
.bg-gradient-x2-red .card-header,
.bg-gradient-x2-red .card-footer,
.bg-gradient-y2-red .card-header,
.bg-gradient-y2-red .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to right, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to bottom, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-pink
{
    background-image: -webkit-linear-gradient(45deg, #c2185b, #f06292);
    background-image:    -moz-linear-gradient(45deg, #c2185b, #f06292);
    background-image:      -o-linear-gradient(45deg, #c2185b, #f06292);
    background-image:         linear-gradient(45deg, #c2185b, #f06292);
    background-repeat: repeat-x;
}

.bg-gradient-x2-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(to right, #f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-y2-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-radial-pink
{
    background-image: -webkit-radial-gradient(circle, #c2185b, #f06292);
    background-image:    -moz-radial-gradient(circle, #c2185b, #f06292);
    background-image:      -o-radial-gradient(circle, #c2185b, #f06292);
    background-image:         radial-gradient(circle, #c2185b, #f06292);
    background-repeat: no-repeat;
}

.bg-gradient-striped-pink
{
    background-image: -webkit-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
}

.bg-gradient-x-pink .card-header,
.bg-gradient-x-pink .card-footer,
.bg-gradient-y-pink .card-header,
.bg-gradient-y-pink .card-footer,
.bg-gradient-directional-pink .card-header,
.bg-gradient-directional-pink .card-footer,
.bg-gradient-radial-pink .card-header,
.bg-gradient-radial-pink .card-footer,
.bg-gradient-striped-pink .card-header,
.bg-gradient-striped-pink .card-footer,
.bg-gradient-x2-pink .card-header,
.bg-gradient-x2-pink .card-footer,
.bg-gradient-y2-pink .card-header,
.bg-gradient-y2-pink .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to right, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to bottom, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-purple
{
    background-image: -webkit-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:    -moz-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:      -o-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:         linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-repeat: repeat-x;
}

.bg-gradient-x2-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(to right, #ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-y2-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-radial-purple
{
    background-image: -webkit-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:    -moz-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:      -o-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:         radial-gradient(circle, #7b1fa2, #ba68c8);
    background-repeat: no-repeat;
}

.bg-gradient-striped-purple
{
    background-image: -webkit-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
}

.bg-gradient-x-purple .card-header,
.bg-gradient-x-purple .card-footer,
.bg-gradient-y-purple .card-header,
.bg-gradient-y-purple .card-footer,
.bg-gradient-directional-purple .card-header,
.bg-gradient-directional-purple .card-footer,
.bg-gradient-radial-purple .card-header,
.bg-gradient-radial-purple .card-footer,
.bg-gradient-striped-purple .card-header,
.bg-gradient-striped-purple .card-footer,
.bg-gradient-x2-purple .card-header,
.bg-gradient-x2-purple .card-footer,
.bg-gradient-y2-purple .card-header,
.bg-gradient-y2-purple .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to right, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to bottom, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue
{
    background-image: -webkit-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:    -moz-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:      -o-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:         linear-gradient(45deg, #1976d2, #64b5f6);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(to right, #64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue
{
    background-image: -webkit-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:    -moz-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:      -o-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:         radial-gradient(circle, #1976d2, #64b5f6);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue
{
    background-image: -webkit-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue .card-header,
.bg-gradient-x-blue .card-footer,
.bg-gradient-y-blue .card-header,
.bg-gradient-y-blue .card-footer,
.bg-gradient-directional-blue .card-header,
.bg-gradient-directional-blue .card-footer,
.bg-gradient-radial-blue .card-header,
.bg-gradient-radial-blue .card-footer,
.bg-gradient-striped-blue .card-header,
.bg-gradient-striped-blue .card-footer,
.bg-gradient-x2-blue .card-header,
.bg-gradient-x2-blue .card-footer,
.bg-gradient-y2-blue .card-header,
.bg-gradient-y2-blue .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to right, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to bottom, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-cyan
{
    background-image: -webkit-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:    -moz-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:      -o-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:         linear-gradient(45deg, #0097a7, #4dd0e1);
    background-repeat: repeat-x;
}

.bg-gradient-x2-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(to right, #4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-y2-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-radial-cyan
{
    background-image: -webkit-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:    -moz-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:      -o-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:         radial-gradient(circle, #0097a7, #4dd0e1);
    background-repeat: no-repeat;
}

.bg-gradient-striped-cyan
{
    background-image: -webkit-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
}

.bg-gradient-x-cyan .card-header,
.bg-gradient-x-cyan .card-footer,
.bg-gradient-y-cyan .card-header,
.bg-gradient-y-cyan .card-footer,
.bg-gradient-directional-cyan .card-header,
.bg-gradient-directional-cyan .card-footer,
.bg-gradient-radial-cyan .card-header,
.bg-gradient-radial-cyan .card-footer,
.bg-gradient-striped-cyan .card-header,
.bg-gradient-striped-cyan .card-footer,
.bg-gradient-x2-cyan .card-header,
.bg-gradient-x2-cyan .card-footer,
.bg-gradient-y2-cyan .card-header,
.bg-gradient-y2-cyan .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to right, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to bottom, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-teal
{
    background-image: -webkit-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:    -moz-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:      -o-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:         linear-gradient(45deg, #00796b, #4db6ac);
    background-repeat: repeat-x;
}

.bg-gradient-x2-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(to right, #4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-teal
{
    background-image: -webkit-radial-gradient(circle, #00796b, #4db6ac);
    background-image:    -moz-radial-gradient(circle, #00796b, #4db6ac);
    background-image:      -o-radial-gradient(circle, #00796b, #4db6ac);
    background-image:         radial-gradient(circle, #00796b, #4db6ac);
    background-repeat: no-repeat;
}

.bg-gradient-striped-teal
{
    background-image: -webkit-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
}

.bg-gradient-x-teal .card-header,
.bg-gradient-x-teal .card-footer,
.bg-gradient-y-teal .card-header,
.bg-gradient-y-teal .card-footer,
.bg-gradient-directional-teal .card-header,
.bg-gradient-directional-teal .card-footer,
.bg-gradient-radial-teal .card-header,
.bg-gradient-radial-teal .card-footer,
.bg-gradient-striped-teal .card-header,
.bg-gradient-striped-teal .card-footer,
.bg-gradient-x2-teal .card-header,
.bg-gradient-x2-teal .card-footer,
.bg-gradient-y2-teal .card-header,
.bg-gradient-y2-teal .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to right, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to bottom, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:    -moz-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:      -o-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:         linear-gradient(45deg, #fbc02d, #fff176);
    background-repeat: repeat-x;
}

.bg-gradient-x2-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(to right, #fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-y2-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-radial-yellow
{
    background-image: -webkit-radial-gradient(circle, #fbc02d, #fff176);
    background-image:    -moz-radial-gradient(circle, #fbc02d, #fff176);
    background-image:      -o-radial-gradient(circle, #fbc02d, #fff176);
    background-image:         radial-gradient(circle, #fbc02d, #fff176);
    background-repeat: no-repeat;
}

.bg-gradient-striped-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
}

.bg-gradient-x-yellow .card-header,
.bg-gradient-x-yellow .card-footer,
.bg-gradient-y-yellow .card-header,
.bg-gradient-y-yellow .card-footer,
.bg-gradient-directional-yellow .card-header,
.bg-gradient-directional-yellow .card-footer,
.bg-gradient-radial-yellow .card-header,
.bg-gradient-radial-yellow .card-footer,
.bg-gradient-striped-yellow .card-header,
.bg-gradient-striped-yellow .card-footer,
.bg-gradient-x2-yellow .card-header,
.bg-gradient-x2-yellow .card-footer,
.bg-gradient-y2-yellow .card-header,
.bg-gradient-y2-yellow .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to right, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to bottom, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:    -moz-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:      -o-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:         linear-gradient(45deg, #ffa000, #ffd54f);
    background-repeat: repeat-x;
}

.bg-gradient-x2-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(to right, #ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-y2-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-radial-amber
{
    background-image: -webkit-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:    -moz-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:      -o-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:         radial-gradient(circle, #ffa000, #ffd54f);
    background-repeat: no-repeat;
}

.bg-gradient-striped-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
}

.bg-gradient-x-amber .card-header,
.bg-gradient-x-amber .card-footer,
.bg-gradient-y-amber .card-header,
.bg-gradient-y-amber .card-footer,
.bg-gradient-directional-amber .card-header,
.bg-gradient-directional-amber .card-footer,
.bg-gradient-radial-amber .card-header,
.bg-gradient-radial-amber .card-footer,
.bg-gradient-striped-amber .card-header,
.bg-gradient-striped-amber .card-footer,
.bg-gradient-x2-amber .card-header,
.bg-gradient-x2-amber .card-footer,
.bg-gradient-y2-amber .card-header,
.bg-gradient-y2-amber .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to right, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to bottom, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:    -moz-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:      -o-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:         linear-gradient(45deg, #455a64, #90a4ae);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(to right, #90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue-grey
{
    background-image: -webkit-radial-gradient(circle, #455a64, #90a4ae);
    background-image:    -moz-radial-gradient(circle, #455a64, #90a4ae);
    background-image:      -o-radial-gradient(circle, #455a64, #90a4ae);
    background-image:         radial-gradient(circle, #455a64, #90a4ae);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue-grey .card-header,
.bg-gradient-x-blue-grey .card-footer,
.bg-gradient-y-blue-grey .card-header,
.bg-gradient-y-blue-grey .card-footer,
.bg-gradient-directional-blue-grey .card-header,
.bg-gradient-directional-blue-grey .card-footer,
.bg-gradient-radial-blue-grey .card-header,
.bg-gradient-radial-blue-grey .card-footer,
.bg-gradient-striped-blue-grey .card-header,
.bg-gradient-striped-blue-grey .card-footer,
.bg-gradient-x2-blue-grey .card-header,
.bg-gradient-x2-blue-grey .card-footer,
.bg-gradient-y2-blue-grey .card-header,
.bg-gradient-y2-blue-grey .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#2c303b), to(#6f85ad));
    background-image: -webkit-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(left, #2c303b 0%, #6f85ad 100%);
    background-image:         linear-gradient(to right, #2c303b 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2c303b), to(#6f85ad));
    background-image: -webkit-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(top, #2c303b 0%, #6f85ad 100%);
    background-image:         linear-gradient(to bottom, #2c303b 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:    -moz-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:      -o-linear-gradient(45deg, #2c303b, #6f85ad);
    background-image:         linear-gradient(45deg, #2c303b, #6f85ad);
    background-repeat: repeat-x;
}

.bg-gradient-x2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(to right, #6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-grey-blue
{
    background-image: -webkit-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:    -moz-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:      -o-radial-gradient(circle, #2c303b, #6f85ad);
    background-image:         radial-gradient(circle, #2c303b, #6f85ad);
    background-repeat: no-repeat;
}

.bg-gradient-striped-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-grey-blue .card-header,
.bg-gradient-x-grey-blue .card-footer,
.bg-gradient-y-grey-blue .card-header,
.bg-gradient-y-grey-blue .card-footer,
.bg-gradient-directional-grey-blue .card-header,
.bg-gradient-directional-grey-blue .card-footer,
.bg-gradient-radial-grey-blue .card-header,
.bg-gradient-radial-grey-blue .card-footer,
.bg-gradient-striped-grey-blue .card-header,
.bg-gradient-striped-grey-blue .card-footer,
.bg-gradient-x2-grey-blue .card-header,
.bg-gradient-x2-grey-blue .card-footer,
.bg-gradient-y2-grey-blue .card-header,
.bg-gradient-y2-grey-blue .card-footer
{
    background-color: transparent;
}

/*
  Gradient color mixin
*/
.bg-gradient-x-purple-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
    background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
    background-image:    -moz-linear-gradient(left, #9f78ff, #32cafe);
    background-image:      -o-linear-gradient(left, #9f78ff, #32cafe);
    background-image:         linear-gradient(to right, #9f78ff, #32cafe);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-purple-blue
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), color-stop(50%, #32cafe), to(#9f78ff));
    background-image: -webkit-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
    background-image:    -moz-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
    background-image:      -o-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
    background-image:         linear-gradient(90deg, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-purple-blue.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #9f78ff;
            box-shadow: 0 0 12px 0 #9f78ff;
}
.btn-bg-gradient-x-purple-blue.active,
.btn-bg-gradient-x-purple-blue:active,
.btn-bg-gradient-x-purple-blue:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), color-stop(50%, #32cafe), to(#9f78ff)) !important;
    background-image: -webkit-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image:    -moz-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image:      -o-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image:         linear-gradient(90deg, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-purple-blue] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-purple-blue] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
    background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
    background-image:    -moz-linear-gradient(left, #9f78ff, #32cafe);
    background-image:      -o-linear-gradient(left, #9f78ff, #32cafe);
    background-image:         linear-gradient(to right, #9f78ff, #32cafe);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-purple-blue] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-purple-blue] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
    background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
    background-image:    -moz-linear-gradient(left, #9f78ff, #32cafe);
    background-image:      -o-linear-gradient(left, #9f78ff, #32cafe);
    background-image:         linear-gradient(to right, #9f78ff, #32cafe);
    background-repeat: repeat-x;
}

.bg-gradient-x-purple-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
    background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
    background-image:    -moz-linear-gradient(left, #a376fc, #f96f9b);
    background-image:      -o-linear-gradient(left, #a376fc, #f96f9b);
    background-image:         linear-gradient(to right, #a376fc, #f96f9b);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-purple-red
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), color-stop(50%, #f96f9b), to(#a376fc));
    background-image: -webkit-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
    background-image:    -moz-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
    background-image:      -o-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
    background-image:         linear-gradient(90deg, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-purple-red.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #a376fc;
            box-shadow: 0 0 12px 0 #a376fc;
}
.btn-bg-gradient-x-purple-red.active,
.btn-bg-gradient-x-purple-red:active,
.btn-bg-gradient-x-purple-red:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), color-stop(50%, #f96f9b), to(#a376fc)) !important;
    background-image: -webkit-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image:    -moz-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image:      -o-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image:         linear-gradient(90deg, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-purple-red] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-purple-red] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
    background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
    background-image:    -moz-linear-gradient(left, #a376fc, #f96f9b);
    background-image:      -o-linear-gradient(left, #a376fc, #f96f9b);
    background-image:         linear-gradient(to right, #a376fc, #f96f9b);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-purple-red] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-purple-red] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
    background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
    background-image:    -moz-linear-gradient(left, #a376fc, #f96f9b);
    background-image:      -o-linear-gradient(left, #a376fc, #f96f9b);
    background-image:         linear-gradient(to right, #a376fc, #f96f9b);
    background-repeat: repeat-x;
}

.bg-gradient-x-blue-green
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
    background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
    background-image:    -moz-linear-gradient(left, #00cef9, #00e6af);
    background-image:      -o-linear-gradient(left, #00cef9, #00e6af);
    background-image:         linear-gradient(to right, #00cef9, #00e6af);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-blue-green
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), color-stop(50%, #00e6af), to(#00cef9));
    background-image: -webkit-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
    background-image:    -moz-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
    background-image:      -o-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
    background-image:         linear-gradient(90deg, #00cef9 0%, #00e6af 50%, #00cef9 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-blue-green.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #00cef9;
            box-shadow: 0 0 12px 0 #00cef9;
}
.btn-bg-gradient-x-blue-green.active,
.btn-bg-gradient-x-blue-green:active,
.btn-bg-gradient-x-blue-green:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), color-stop(50%, #00e6af), to(#00cef9)) !important;
    background-image: -webkit-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image:    -moz-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image:      -o-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image:         linear-gradient(90deg, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-blue-green] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-blue-green] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
    background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
    background-image:    -moz-linear-gradient(left, #00cef9, #00e6af);
    background-image:      -o-linear-gradient(left, #00cef9, #00e6af);
    background-image:         linear-gradient(to right, #00cef9, #00e6af);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-blue-green] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-blue-green] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
    background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
    background-image:    -moz-linear-gradient(left, #00cef9, #00e6af);
    background-image:      -o-linear-gradient(left, #00cef9, #00e6af);
    background-image:         linear-gradient(to right, #00cef9, #00e6af);
    background-repeat: repeat-x;
}

.bg-gradient-x-orange-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8008), to(#ffc837));
    background-image: -webkit-linear-gradient(left, #ff8008, #ffc837);
    background-image:    -moz-linear-gradient(left, #ff8008, #ffc837);
    background-image:      -o-linear-gradient(left, #ff8008, #ffc837);
    background-image:         linear-gradient(to right, #ff8008, #ffc837);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-orange-yellow
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8008), color-stop(50%, #ffc837), to(#ff8008));
    background-image: -webkit-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%);
    background-image:    -moz-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%);
    background-image:      -o-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%);
    background-image:         linear-gradient(90deg, #ff8008 0%, #ffc837 50%, #ff8008 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-orange-yellow.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #ff8008;
            box-shadow: 0 0 12px 0 #ff8008;
}
.btn-bg-gradient-x-orange-yellow.active,
.btn-bg-gradient-x-orange-yellow:active,
.btn-bg-gradient-x-orange-yellow:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8008), color-stop(50%, #ffc837), to(#ff8008)) !important;
    background-image: -webkit-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%) !important;
    background-image:    -moz-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%) !important;
    background-image:      -o-linear-gradient(left, #ff8008 0%, #ffc837 50%, #ff8008 100%) !important;
    background-image:         linear-gradient(90deg, #ff8008 0%, #ffc837 50%, #ff8008 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-orange-yellow] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-orange-yellow] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8008), to(#ffc837));
    background-image: -webkit-linear-gradient(left, #ff8008, #ffc837);
    background-image:    -moz-linear-gradient(left, #ff8008, #ffc837);
    background-image:      -o-linear-gradient(left, #ff8008, #ffc837);
    background-image:         linear-gradient(to right, #ff8008, #ffc837);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-orange-yellow] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-orange-yellow] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8008), to(#ffc837));
    background-image: -webkit-linear-gradient(left, #ff8008, #ffc837);
    background-image:    -moz-linear-gradient(left, #ff8008, #ffc837);
    background-image:      -o-linear-gradient(left, #ff8008, #ffc837);
    background-image:         linear-gradient(to right, #ff8008, #ffc837);
    background-repeat: repeat-x;
}

.bg-gradient-x-blue-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#514a9d), to(#24c6dc));
    background-image: -webkit-linear-gradient(left, #514a9d, #24c6dc);
    background-image:    -moz-linear-gradient(left, #514a9d, #24c6dc);
    background-image:      -o-linear-gradient(left, #514a9d, #24c6dc);
    background-image:         linear-gradient(to right, #514a9d, #24c6dc);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-blue-cyan
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#514a9d), color-stop(50%, #24c6dc), to(#514a9d));
    background-image: -webkit-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%);
    background-image:    -moz-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%);
    background-image:      -o-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%);
    background-image:         linear-gradient(90deg, #514a9d 0%, #24c6dc 50%, #514a9d 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-blue-cyan.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #514a9d;
            box-shadow: 0 0 12px 0 #514a9d;
}
.btn-bg-gradient-x-blue-cyan.active,
.btn-bg-gradient-x-blue-cyan:active,
.btn-bg-gradient-x-blue-cyan:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#514a9d), color-stop(50%, #24c6dc), to(#514a9d)) !important;
    background-image: -webkit-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%) !important;
    background-image:    -moz-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%) !important;
    background-image:      -o-linear-gradient(left, #514a9d 0%, #24c6dc 50%, #514a9d 100%) !important;
    background-image:         linear-gradient(90deg, #514a9d 0%, #24c6dc 50%, #514a9d 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-blue-cyan] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-blue-cyan] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#514a9d), to(#24c6dc));
    background-image: -webkit-linear-gradient(left, #514a9d, #24c6dc);
    background-image:    -moz-linear-gradient(left, #514a9d, #24c6dc);
    background-image:      -o-linear-gradient(left, #514a9d, #24c6dc);
    background-image:         linear-gradient(to right, #514a9d, #24c6dc);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-blue-cyan] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-blue-cyan] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#514a9d), to(#24c6dc));
    background-image: -webkit-linear-gradient(left, #514a9d, #24c6dc);
    background-image:    -moz-linear-gradient(left, #514a9d, #24c6dc);
    background-image:      -o-linear-gradient(left, #514a9d, #24c6dc);
    background-image:         linear-gradient(to right, #514a9d, #24c6dc);
    background-repeat: repeat-x;
}

.bg-gradient-x-red-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
    background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
    background-image:    -moz-linear-gradient(left, #ff5858, #e888b7);
    background-image:      -o-linear-gradient(left, #ff5858, #e888b7);
    background-image:         linear-gradient(to right, #ff5858, #e888b7);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-red-pink
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), color-stop(50%, #e888b7), to(#ff5858));
    background-image: -webkit-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
    background-image:    -moz-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
    background-image:      -o-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
    background-image:         linear-gradient(90deg, #ff5858 0%, #e888b7 50%, #ff5858 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-red-pink.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #ff5858;
            box-shadow: 0 0 12px 0 #ff5858;
}
.btn-bg-gradient-x-red-pink.active,
.btn-bg-gradient-x-red-pink:active,
.btn-bg-gradient-x-red-pink:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), color-stop(50%, #e888b7), to(#ff5858)) !important;
    background-image: -webkit-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image:    -moz-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image:      -o-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image:         linear-gradient(90deg, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-red-pink] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-red-pink] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
    background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
    background-image:    -moz-linear-gradient(left, #ff5858, #e888b7);
    background-image:      -o-linear-gradient(left, #ff5858, #e888b7);
    background-image:         linear-gradient(to right, #ff5858, #e888b7);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-red-pink] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-red-pink] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
    background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
    background-image:    -moz-linear-gradient(left, #ff5858, #e888b7);
    background-image:      -o-linear-gradient(left, #ff5858, #e888b7);
    background-image:         linear-gradient(to right, #ff5858, #e888b7);
    background-repeat: repeat-x;
}

.bg-gradient-x-blue-purple-1
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
    background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:    -moz-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:      -o-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:         linear-gradient(to right, #7396ff, #f8a1ec);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-blue-purple-1
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), color-stop(50%, #f8a1ec), to(#7396ff));
    background-image: -webkit-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
    background-image:    -moz-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
    background-image:      -o-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
    background-image:         linear-gradient(90deg, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-blue-purple-1.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #7396ff;
            box-shadow: 0 0 12px 0 #7396ff;
}
.btn-bg-gradient-x-blue-purple-1.active,
.btn-bg-gradient-x-blue-purple-1:active,
.btn-bg-gradient-x-blue-purple-1:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), color-stop(50%, #f8a1ec), to(#7396ff)) !important;
    background-image: -webkit-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image:    -moz-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image:      -o-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image:         linear-gradient(90deg, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-blue-purple-1] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-blue-purple-1] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
    background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:    -moz-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:      -o-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:         linear-gradient(to right, #7396ff, #f8a1ec);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-blue-purple-1] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-blue-purple-1] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
    background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:    -moz-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:      -o-linear-gradient(left, #7396ff, #f8a1ec);
    background-image:         linear-gradient(to right, #7396ff, #f8a1ec);
    background-repeat: repeat-x;
}

.bg-gradient-x-blue-purple-2
{
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
    background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:    -moz-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:      -o-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:         linear-gradient(to right, #85a6f3, #d7b9f1);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-blue-purple-2
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), color-stop(50%, #d7b9f1), to(#85a6f3));
    background-image: -webkit-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
    background-image:    -moz-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
    background-image:      -o-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
    background-image:         linear-gradient(90deg, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-blue-purple-2.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #85a6f3;
            box-shadow: 0 0 12px 0 #85a6f3;
}
.btn-bg-gradient-x-blue-purple-2.active,
.btn-bg-gradient-x-blue-purple-2:active,
.btn-bg-gradient-x-blue-purple-2:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), color-stop(50%, #d7b9f1), to(#85a6f3)) !important;
    background-image: -webkit-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image:    -moz-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image:      -o-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image:         linear-gradient(90deg, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-blue-purple-2] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-blue-purple-2] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
    background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:    -moz-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:      -o-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:         linear-gradient(to right, #85a6f3, #d7b9f1);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-blue-purple-2] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-blue-purple-2] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
    background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:    -moz-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:      -o-linear-gradient(left, #85a6f3, #d7b9f1);
    background-image:         linear-gradient(to right, #85a6f3, #d7b9f1);
    background-repeat: repeat-x;
}

.bg-gradient-x-blue-purple-3
{
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
    background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:    -moz-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:      -o-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:         linear-gradient(to right, #bccdff, #ecd2f8);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-blue-purple-3
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), color-stop(50%, #ecd2f8), to(#bccdff));
    background-image: -webkit-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
    background-image:    -moz-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
    background-image:      -o-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
    background-image:         linear-gradient(90deg, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-blue-purple-3.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #bccdff;
            box-shadow: 0 0 12px 0 #bccdff;
}
.btn-bg-gradient-x-blue-purple-3.active,
.btn-bg-gradient-x-blue-purple-3:active,
.btn-bg-gradient-x-blue-purple-3:hover
{
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), color-stop(50%, #ecd2f8), to(#bccdff)) !important;
    background-image: -webkit-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image:    -moz-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image:      -o-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image:         linear-gradient(90deg, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important; 
    background-position: right center;
}

body.vertical-layout[data-color=bg-gradient-x-blue-purple-3] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-blue-purple-3] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
    background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:    -moz-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:      -o-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:         linear-gradient(to right, #bccdff, #ecd2f8);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-blue-purple-3] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-blue-purple-3] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
    background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:    -moz-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:      -o-linear-gradient(left, #bccdff, #ecd2f8);
    background-image:         linear-gradient(to right, #bccdff, #ecd2f8);
    background-repeat: repeat-x;
}

@media (max-width: 767.98px)
{
    body.horizontal-layout .main-menu-content
    {
        background: #fff !important;
    }
}
