body {
    background-color: lightgray;
}
.navbar-header .navbar-brand {text-align:center}
.main-menu.menu-light .main-menu-header {padding:40px}
.tab-content {
	padding:1rem;
    border-radius: .25rem;
	border-top-left-radius: 0;
    border: 1px solid #cacfe7;
}
.nav.nav-tabs .nav-item .nav-link.active
{
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
#logo {width:80%}
.logo-image0{fill:#2D97C6;}
.logo-image1{fill:#353635;}
.logo-image2{fill:#dcdcdc;}
.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
    color: #ffffff;
    background-color: inherit;
}
.list-pagination-paginator .btn {margin:5px}
.main-menu.menu-light .navigation > li ul .active > a {color:#2D97C6;}
.main-menu.menu-light .navigation > li ul .active > a:before, .main-menu.menu-light .navigation > li.open .hover > a:before {border-color: #2D97C6;background-color: #2D97C6;}
body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .hover > a > i, body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation:hover > a > i, body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .open > a > i {-webkit-box-shadow: 0 0 20px rgba(45, 151, 198, .3);box-shadow: 0 0 20px rgba(45, 151, 198, .3);}
.content-header-right .global-actions {width:100%;}
.actions>a {margin: 0 5px;}
@media (min-width: 992px) {
.sidebar {
    width: 100%;
    vertical-align: top;
}
}
.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container--bootstrap .select2-selection {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #555;
    font-size: 14px;
    outline: 0;
}
.select2-container--bootstrap .select2-selection--multiple {
    min-height: 34px;
    padding: 0;
    height: auto;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    color: #555;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin: 5px 0 0 6px;
    padding: 0 6px;
}
.select2-container--bootstrap .select2-selection {
    border: 0;
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 1px rgba(43,45,80,.16), 0 0 0 1px rgba(6,122,184,0), 0 0 0 2px rgba(6,122,184,0), 0 1px 1px rgba(0,0,0,.08);
    color: var(--text-color);
    max-width: 350px;
    transition: box-shadow .08s ease-in,color .08s ease-in;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    margin: 4px 0 0 7px;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    color: var(--text-color);
}
.select2-container .select2-search--inline {
    float: left;
}
.select2-container .select2-search--inline {
    float: left;
}
.select2-container--bootstrap .select2-selection .select2-search--inline {
    margin: 0;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    line-height: 1.42857143;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.heading-elements .button-action {display:inline-block;}

.button-wrap {
  position: relative;
  text-align: center;
}

.button-wrap .button-label {
  display: inline-block;
  padding: 1em 2em;
  margin: 0.5em;
  cursor: pointer;
  color: #292929;
  border-radius: 0.25em;
  background: #efefef;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button-wrap .button-label h1 {
  font-size: 1em;
  font-family: "Lato", sans-serif;
}
.button-wrap .button-label:hover {
  background: #d6d6d6;
  color: #101010;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
}
.button-wrap .button-label:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);
}
@media (max-width: 40em) {
  .button-wrap .button-label {
    padding: 0em 1em 3px;
    margin: 0.25em;
  }
}
.button-wrap .radio-label:checked + .button-label {
  background: #4183D7;
  color: #efefef;
}
.button-wrap .radio-label:checked + .button-label:hover {
  background: #2c75d2;
  color: #e2e2e2;
}